import { get } from "lodash";

export const constructionVisitUtil = {
  renderTitleAdditionLabel: (question, isError = false) => {
    const { description, min_options_required, max_options_allowed, is_required } = question;
    let limitLabel = "";

    if (
      min_options_required &&
      max_options_allowed &&
      min_options_required === max_options_allowed
    ) {
      limitLabel = `請勾選 ${max_options_allowed} 項`;
    } else {
      if (min_options_required) {
        limitLabel = `最少勾選 ${min_options_required} 項`;
      }

      if (max_options_allowed) {
        limitLabel += `${min_options_required ? "、" : ""}最多勾選 ${max_options_allowed} 項`;
      }
    }

    if (description && limitLabel) {
      limitLabel = "，" + limitLabel;
    }

    if (min_options_required || max_options_allowed) {
      return (
        <span style={{ fontSize: "12px", color: isError ? "inherit" : "#949494" }}>
          {description ? (
            <span>
              （{description}
              {isError ? "" : limitLabel}）
            </span>
          ) : (
            <span>{isError ? "" : `（${limitLabel}）`}</span>
          )}
        </span>
      );
    }

    return null;
  },

  getSurveyQuestionFirstError: (errors, questionPath, questionType) => {
    const questionError = get(errors, questionPath);
    // checkboxRequired
    if (questionError) {
      if (questionError.type === "checkboxRequired") {
        return { message: questionError.message };
      }

      if (questionError.options?.length > 0) {
        const { value, additional_note } =
          questionError.options.find(optionError => optionError) || {};

        if (questionType === "checkbox" && additional_note) {
          // 補充文字的錯誤要在該 input 下自己呈現，故不需回傳錯誤訊息，只回傳一個無資料的物件讓題目顯示錯誤狀態即可
          return { message: "" };
        }

        if (questionType === "text") {
          // 文字的錯誤要在該 input 下自己呈現，故不需回傳錯誤訊息，只回傳一個無資料的物件讓題目顯示錯誤狀態即可
          return { message: "" };
        }

        return { message: "" };
      }
    }

    return null;
  },

  isQuestionRequired: question => {
    return (
      (question.type === "checkbox" && !!question.min_options_required) ||
      (question.type === "text" && !!question.is_required)
    );
  },

  transferQuestionsToFillResult: (questions = []) => {
    return questions.map(question => {
      const allFillAnswer = question.options
        .filter(option => option.value)
        .map(option => {
          let value = null;
          if (option.type === "checkbox") {
            value = option.id;
          }
          if (option.type === "text") {
            value = option.value;
          }
          return {
            value: value,
            additional_note: option.additional_note || null
          };
        });

      return {
        question_id: question.id,
        answers:
          question.visible && allFillAnswer.length > 0
            ? allFillAnswer
            : [{ value: null, additional_note: null }]
      };
    });
  }
};
