export const constructionReservationContstans = {
  // 取得賞屋預約清單相關
  GET_ALL_REQUEST: "CONSTRUCTION_RESERVATION_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "CONSTRUCTION_RESERVATION_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "CONSTRUCTION_RESERVATION_GET_ALL_FAILURE",
  RESET_ITEMS: "CONSTRUCTION_RESERVATION_RESET_ITEMS",
  RESET_GET_ALL: "CONSTRUCTION_RESERVATION_RESET_GET_ALL",

  // 指派接待人員相關
  ASSIGN_RECEPTIONIST_REQUEST: "CONSTRUCTION_RESERVATION_ASSIGN_RECEPTIONIST_REQUEST",
  ASSIGN_RECEPTIONIST_SUCCESS: "CONSTRUCTION_RESERVATION_ASSIGN_RECEPTIONIST_SUCCESS",
  ASSIGN_RECEPTIONIST_FAILURE: "CONSTRUCTION_RESERVATION_ASSIGN_RECEPTIONIST_FAILURE",
  RESET_ASSIGN_RECEPTIONIST: "CONSTRUCTION_RESERVATION_RESET_ASSIGN_RECEPTIONIST"
};
