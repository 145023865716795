import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ContentRouter from "./ContentRouter";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#e4e4e4",
    flex: "auto",
    display: "flex"
  },
  contentRoot: {
    backgroundColor: "white",
  },
  main: {
    overflow: "hidden",
    height: "100%"
  }
}));

const Content = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Container
        maxWidth={theme.hyl.maxContentWidthBreakpoint}
        disableGutters={true}
        className={classes.contentRoot}
      >
        <main className={classes.main}>
          <ContentRouter />
        </main>
      </Container>
    </div>
  );
};

export default Content;
