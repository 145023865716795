import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: "5px",
    color: theme.palette.error.main,
    alignSelf: "center"
  },
  okButton: {
    backgroundColor: "#b96161",
    "&:hover": {
      backgroundColor: "#b55353"
    }
  }
}));

const ErrorModal = props => {
  const classes = useStyles();
  const {
    open,
    okText = "確定",
    onOk,
    okButtonProps = {},
    title = "操作失敗",
    content,
    onCloseModal,
    ...others
  } = props;

  const handleOk = e => {
    if (onOk && typeof onOk === "function") {
      onOk(e);
    }

    if (!e.defaultPrevented) {
      onCloseModal();
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth={"xs"} {...others}>
      <DialogTitle>
        <Box display="flex">
          <Box display="flex">
            <ErrorOutlineIcon className={classes.icon} />
          </Box>
          <span>{title}</span>
        </Box>
      </DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={handleOk}
          variant="contained"
          color="primary"
          fullWidth
          className={clsx(classes.okButton, okButtonProps.className)}
          {...okButtonProps}
        >
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModal;
