import { constructionVisitRecordsConstants } from "../constants";
import { constructionVisitService } from "../services";
import { alertActions } from "../actions";

export const constructionVisitRecordsActions = {
  /**
   * 取得賞屋作業單紀錄列表
   */
  getAll: (conditions, isOverrideExistedConditions) => {
    return async (dispatch, getState) => {
      try {
        const {
          auth: { selectedConstruction },
          constructionVisitRecords: {
            records: { conditions: currentConditions }
          }
        } = getState();

        dispatch({
          type: constructionVisitRecordsConstants.GET_ALL_REQUEST
        });

        let finalConditions = conditions;
        if (isOverrideExistedConditions) {
          finalConditions = {
            ...currentConditions,
            ...finalConditions
          };
        }

        const { total, items } = await constructionVisitService.getVisitRecords(
          selectedConstruction,
          finalConditions
        );

        dispatch({
          type: constructionVisitRecordsConstants.GET_ALL_SUCCESS,
          total,
          items,
          conditions: finalConditions
        });
      } catch (error) {
        dispatch({
          type: constructionVisitRecordsConstants.GET_ALL_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   * 重設查詢結果資料（不包含查詢條件）
   */
  resetItems: () => {
    return {
      type: constructionVisitRecordsConstants.RESET_ITEMS
    };
  },
  /**
   * 重設所有列表資料
   */
  resetAll: () => {
    return {
      type: constructionVisitRecordsConstants.RESET_GET_ALL
    };
  },
  /**
   * 指派作業單銷售人員
   *
   * @param {array} constructionVisitIDs - 要指派的作業單 IDs
   * @param {array} salesPersonsUUIDs - 要指派的銷售人員 UUIDs
   * @param {function} successCb - 成功執行 callback
   */
  assignVisitReceptionist: (constructionVisitIDs, salesPersonsUUIDs, successCb) => {
    return async (dispatch, getState) => {
      try {
        const {
          auth: { selectedConstruction }
        } = getState();

        dispatch({
          type: constructionVisitRecordsConstants.SETTING_VISIT_RECPTIONIST_REQUEST
        });

        const { message } = await constructionVisitService.assignVisitReceptionist(
          selectedConstruction,
          constructionVisitIDs,
          salesPersonsUUIDs
        );

        dispatch(alertActions.show(message));

        successCb && successCb();

        dispatch({
          type: constructionVisitRecordsConstants.SETTING_VISIT_RECPTIONIST_SUCCESS
        });

        // 重新查詢資料
        const {
          records: { conditions }
        } = getState().constructionVisitRecords;
        dispatch(constructionVisitRecordsActions.getAll(conditions));
      } catch (error) {
        dispatch(alertActions.show(error.message, "error"));
        dispatch({
          type: constructionVisitRecordsConstants.SETTING_VISIT_RECPTIONIST_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   * 移除指派作業單銷售人員
   *
   * @param {array} constructionVisitIDs - 要移除指派的作業單 IDs
   * @param {array} salesPersonsUUIDs - 要移除指派的銷售人員 UUIDs
   * @param {function} successCb - 成功執行 callback
   */
  removeAssignedVisitReceptionist: (constructionVisitIDs, salesPersonsUUIDs, successCb) => {
    return async (dispatch, getState) => {
      try {
        const {
          auth: { selectedConstruction }
        } = getState();

        dispatch({
          type: constructionVisitRecordsConstants.SETTING_VISIT_RECPTIONIST_REQUEST
        });

        const { message } = await constructionVisitService.removeAssignedVisitReceptionist(
          selectedConstruction,
          constructionVisitIDs,
          salesPersonsUUIDs
        );

        dispatch(alertActions.show(message));

        successCb && successCb();

        dispatch({
          type: constructionVisitRecordsConstants.SETTING_VISIT_RECPTIONIST_SUCCESS
        });

        // 重新查詢資料
        const {
          records: { conditions }
        } = getState().constructionVisitRecords;
        dispatch(constructionVisitRecordsActions.getAll(conditions));
      } catch (error) {
        dispatch(alertActions.show(error.message, "error"));
        dispatch({
          type: constructionVisitRecordsConstants.SETTING_VISIT_RECPTIONIST_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   * 設定忽略
   *
   * @param {string} constructionVisitUUIDs - 賞屋作業單 UUIDs
   * @param {boolean} visible - 資料是否設定可見
   * @param {function} successCb - 成功執行 callback
   */
  ignoreVisitRecord: (constructionVisitUUIDs, visible, successCb) => {
    return async (dispatch, getState) => {
      try {
        const {
          auth: { selectedConstruction }
        } = getState();

        dispatch({
          type: constructionVisitRecordsConstants.IGNORE_VISIT_RECORD_REQUEST
        });

        const { message } = await constructionVisitService.ingoreVisitRecords(
          selectedConstruction,
          {
            ids: constructionVisitUUIDs,
            visible: visible
          }
        );

        dispatch(alertActions.show(message));

        successCb && successCb();

        dispatch({
          type: constructionVisitRecordsConstants.IGNORE_VISIT_RECORD_SUCCESS
        });

        // 重新查詢資料
        const {
          records: { conditions }
        } = getState().constructionVisitRecords;
        dispatch(constructionVisitRecordsActions.getAll(conditions));
      } catch (error) {
        dispatch(alertActions.show(error.message, "error"));
        dispatch({
          type: constructionVisitRecordsConstants.IGNORE_VISIT_RECORD_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   * 設定約定接待時間
   *
   * @param {number} visitRecordId - 作業單 id
   * @param {string} telephone - 電話
   * @param {function} successCb - 成功執行 callback
   */
  settingAppointmentAt: (visitRecordId, telephone, info, successCb) => {
    return async (dispatch, getState) => {
      try {
        const {
          auth: { selectedConstruction }
        } = getState();

        dispatch({
          type: constructionVisitRecordsConstants.SETTING_APPOINTMENT_AT_REQUEST
        });

        const { message, data } = await constructionVisitService.settingAppointment(
          selectedConstruction,
          telephone,
          info
        );

        dispatch(alertActions.show(message));
        successCb && successCb();
        dispatch({
          type: constructionVisitRecordsConstants.SETTING_APPOINTMENT_AT_SUCCESS
        });
        dispatch({
          type: constructionVisitRecordsConstants.REPLACE_ITEM,
          id: visitRecordId,
          newItem: data
        });

        setTimeout(() => {
          dispatch(constructionVisitRecordsActions.clearRecordHighlight(visitRecordId));
        }, 4500);
      } catch (error) {
        dispatch({
          type: constructionVisitRecordsConstants.SETTING_APPOINTMENT_AT_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   * 取消約定接待時間
   *
   * @param {number} visitRecordId - 作業單 id
   * @param {string} reservationId - 賞屋預約單 id
   * @param {function} successCb - 成功執行 callback
   */
  cancelAppointmentAt: (visitRecordId, reservationId, info, successCb) => {
    return async (dispatch, getState) => {
      try {
        dispatch({
          type: constructionVisitRecordsConstants.CANCEL_APPOINTMENT_AT_REQUEST
        });

        const { message, data } = await constructionVisitService.cancelAppointment(
          reservationId,
          info
        );

        dispatch(alertActions.show(message));

        successCb && successCb();

        dispatch({
          type: constructionVisitRecordsConstants.CANCEL_APPOINTMENT_AT_SUCCESS
        });
        dispatch({
          type: constructionVisitRecordsConstants.REPLACE_ITEM,
          id: visitRecordId,
          newItem: data
        });

        setTimeout(() => {
          dispatch(constructionVisitRecordsActions.clearRecordHighlight(visitRecordId));
        }, 4500);
      } catch (error) {
        dispatch({
          type: constructionVisitRecordsConstants.CANCEL_APPOINTMENT_AT_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   * 清除新資料的醒目樣式
   *
   * @param {number} visitRecordId - 作業單 id
   */
  clearRecordHighlight: visitRecordId => {
    return {
      type: constructionVisitRecordsConstants.CLEAR_ITEM_HIGHLIGHT,
      id: visitRecordId
    };
  },
  /**
   * 重設約定接待相關操作
   */
  resetAppointment() {
    return {
      type: constructionVisitRecordsConstants.RESET_APPOINTMENT
    };
  },
  /**
   * 更新賞屋作業單列表的某作業單持續追蹤資訊
   *
   * @param {string} recordId - 賞屋作業單 id
   * @param {object} keepTrackingInfo - 賞屋作業單的持續追蹤相關資訊
   * @returns
   */
  replaceKeepTrackingInfoInVisitRecords: (recordId, keepTrackingInfo) => {
    return {
      type: constructionVisitRecordsConstants.REPLACE_KEEP_TRACKING_INFO,
      id: recordId,
      info: keepTrackingInfo
    };
  },
  /**
   * 更新賞屋作業單列表的某作業單回籠資訊
   * @param {string} recordUUID - 賞屋作業單 uuid
   * @param {object} visitAgainInfo - 賞屋作業單的回籠相關資訊
   * @returns 
   */
  replaceVisitAgainInfoInVisitRecords: (recordUUID, visitAgainInfo) => {
    return {
      type: constructionVisitRecordsConstants.REPLACE_VISIT_AGAIN_INFO,
      uuid: recordUUID,
      info: visitAgainInfo
    };
  }
};
