import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadableButton = ({ loading, disabled, children, style, ...others }) => {
  const buttonDisabled = loading || disabled;

  return (
    <Button
      disabled={buttonDisabled}
      style={{ ...style, ...(buttonDisabled ? { backgroundColor: "#0000001f" } : {}) }}
      {...others}
    >
      {loading && <CircularProgress color="inherit" size={16} style={{ marginRight: "8px" }} />}
      {children}
    </Button>
  );
};

export default LoadableButton;
