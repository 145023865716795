export const common = {
  /**
   *
   * Flatting Object
   *
   * @param {object} param.target - Falt Object Target.
   * @param {function} param.flatBy - Recursive By
   * @param {string} param.prefixKey - Prefix Key String.
   * @param {string} param.contactChar - Contact Char Between Nested Path.
   *
   * @description - 將物件遞迴攤平，並依據遞迴路徑串接成 key 值
   *
   * @return {object} Flatted Object
   */
  objectFlatDeep({ target, flatBy = value => value, prefixKey = "", contactChar = "." }) {
    if (prefixKey) {
      prefixKey += contactChar;
    }

    return Object.entries(target).reduce((acc, [key, value]) => {
      if (flatBy(value)) {
        return {
          ...acc,
          [prefixKey + key]: value
        };
      } else {
        return {
          ...acc,
          ...this.objectFlatDeep({
            target: value,
            flatBy,
            prefixKey: prefixKey + key,
            contactChar
          })
        };
      }
    }, {});
  }
};
