/**
 * 各資料狀態對應的預設相關屬性，如：狀態名稱、顏色、描述 ... 等
 */
export const statusMap = {
  /**
   * 建案狀態
   */
  constructionStatus: {
    sellout: {
      label: "已完銷",
      color: "rgb(213 211 211)"
    },
    selling: {
      label: "熱銷中",
      color: "rgb(245 34 45)"
    },
    coming_soon: {
      label: "即將推出",
      color: "rgb(250 84 28)"
    }
  }
};
