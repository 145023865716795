import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Chip from "@material-ui/core/Chip";
import { router, statusMap } from "../utils";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// 建案佳績 menu
const ConstructionMenu = withStyles({
  root: {
    cursor: "default",
    padding: "8px"
  },
  paper: {
    maxHeight: "60vh",
    overflowY: "scroll",
    minWidth: "300px"
  },
  pagerMenuItem: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
    "& .city": {
      color: "#c3c3c3",
      fontSize: "14px",
      marginRight: "8px"
    },
    "& .name": {
      display: "inline-block",
      width: "140px"
    }
  },
  pagerSelectedMenuItem: {
    backgroundColor: "#dff2ff !important", // 沒有 important 會蓋不掉原本的樣式
    "& .name": {
      fontWeight: "800"
    }
  },
  pagerTagContainer: {
    display: "inline-flex",
    alignItems: "center"
  },
  pagerDivider: {
    backgroundColor: "#e2dada",
    height: "12px"
  },
  pagerTag: {
    color: "white",
    marginLeft: "8px"
  },
  pagerTagLabel: {
    fontSize: "8px"
  },
  pagerTagRoot: {
    height: "20px",
    borderRadius: "6px",
    padding: "2px"
  }
})(
  ({
    classes,
    selectedConstruction,
    constructions,
    constructionStatusColorMap,
    onSelectConstruction
  }) => {
    const navRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleOpenMenu = () => setOpen(true);
    const handleCloseMenu = () => setOpen(false);

    return (
      constructions && (
        <Box
          className={classes.root}
          onMouseEnter={handleOpenMenu}
          onMouseLeave={handleCloseMenu}
          onClick={handleOpenMenu}
        >
          <Typography ref={navRef}>建案佳績</Typography>
          {constructions.length > 0 && (
            <Popper
              open={open}
              // open={true}
              anchorEl={navRef?.current}
              placement="top"
              role={undefined}
              transition
              disablePortal
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleCloseMenu}>
                  <MenuList>
                    {constructions.map(construction => (
                      <Link
                        key={construction.id}
                        component={RouterLink}
                        to={router.getPath("ReserveConstruction", {
                          constructionUUID: construction.uuid
                        })}
                        onClick={() => {
                          onSelectConstruction(construction.id);
                          handleCloseMenu();
                        }}
                      >
                        <MenuItem
                          selected={
                            selectedConstruction && selectedConstruction.uuid === construction.uuid
                          }
                          className={classes.pagerMenuItem}
                          classes={{ selected: classes.pagerSelectedMenuItem }}
                        >
                          <Typography className="city">{construction.city}</Typography>
                          <Typography className="name">{construction.name}</Typography>
                          <span className={classes.pagerTagContainer}>
                            <Chip
                              label={statusMap.constructionStatus[construction.status]?.label || ""}
                              size="small"
                              className={classes.pagerTag}
                              classes={{ root: classes.pagerTagRoot, label: classes.pagerTagLabel }}
                              style={{
                                backgroundColor: constructionStatusColorMap.get(construction.status)
                                  ?.bgColor,
                                color:
                                  statusMap.constructionStatus[construction.status]?.color || null,
                                borderColor:
                                  statusMap.constructionStatus[construction.status]?.color || null
                              }}
                              variant="outlined"
                            />
                          </span>
                        </MenuItem>
                      </Link>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          )}
        </Box>
      )
    );
  }
);

// 相關連結 menu
const RelatedCompaniesMenu = withStyles({
  root: {
    cursor: "default",
    padding: "8px"
  }
})(({ classes, relatedCompanies }) => {
  const navRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpenMenu = () => setOpen(true);
  const handleCloseMenu = () => setOpen(false);

  return (
    relatedCompanies && (
      <Box
        className={classes.root}
        onMouseEnter={handleOpenMenu}
        onMouseLeave={handleCloseMenu}
        onClick={handleOpenMenu}
      >
        <Typography ref={navRef}>相關企業</Typography>
        {relatedCompanies.length > 0 && (
          <Popper
            open={open}
            anchorEl={navRef?.current}
            placement="top"
            role={undefined}
            transition
            disablePortal
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList autoFocusItem={true}>
                  {relatedCompanies.map(relatedCompany => (
                    <Link
                      key={relatedCompany.title}
                      href={relatedCompany.url}
                      target="_blank"
                      onClick={handleCloseMenu}
                    >
                      <MenuItem>{relatedCompany.title}</MenuItem>
                    </Link>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        )}
      </Box>
    )
  );
});

// 下載 APP menu
const DownloadAppMenu = withStyles({
  root: {
    cursor: "default",
    padding: "8px"
  }
})(({ classes, downloadAppUrl }) => {
  return (
    downloadAppUrl && (
      <Box className={classes.root}>
        <Typography>
          <Link href={downloadAppUrl} target="_blank" color="inherit">
            下載 APP
          </Link>
        </Typography>
      </Box>
    )
  );
});

// 隱私權政策 menu
const PrivacyPolicy = withStyles({
  root: {
    cursor: "default",
    padding: "8px"
  }
})(({ classes }) => {
  return (
    <Box className={classes.root}>
      <Typography>
        <Link component={RouterLink} to={router.getPath("PrivacyPolicy")} color="inherit">
          隱私權政策
        </Link>
      </Typography>
    </Box>
  );
});

// 前往後台 menu
const AdminHomePage = withStyles({
  root: {
    cursor: "default",
    padding: "8px"
  }
})(({ classes }) => {
  return (
    <Box className={classes.root}>
      <Typography>
        <Link component={RouterLink} to={router.getPath("Admin.Home")} color="inherit">
        進入後台
        </Link>
      </Typography>
    </Box>
  );
});

// 大裝置 menus
const HeaderDesktopMenus = withStyles({})(
  ({
    selectedConstruction,
    constructions,
    constructionStatusColorMap,
    onSelectConstruction,
    relatedCompanies,
    downloadAppUrl
  }) => {
    return (
      <Grid container>
        <Grid item>
          <ConstructionMenu
            selectedConstruction={selectedConstruction}
            constructions={constructions}
            constructionStatusColorMap={constructionStatusColorMap}
            onSelectConstruction={onSelectConstruction}
          />
        </Grid>
        <Grid item>
          <RelatedCompaniesMenu relatedCompanies={relatedCompanies} />
        </Grid>
        {/* <Grid item>
        <DownloadAppMenu downloadAppUrl={downloadAppUrl} />
      </Grid> */}
        <Grid item>
          <PrivacyPolicy />
        </Grid>
        <Grid item>
          <AdminHomePage />
        </Grid>
      </Grid>
    );
  }
);

export default HeaderDesktopMenus;
