import { constructionVisitSurveyConstants } from "../constants";
import { flatten } from "lodash";

export const CONSTRUCTION_VISIT_SURVEY_TYPE = {
  ALL: "ALL",
  PROFILE: "PROFILE",
  FEEDBACK: "FEEDBACK",
  TRACK: "TRACK"
};

const sortedByQuestionPriority = (question1, question2) => question1.priority - question2.priority;

// 答案回填至題目選項內
const transferAnswerInSurveyQuestions = (questions = []) => {
  // 取得所有 answer
  const allAnswer = questions.reduce((accumulator, question) => {
    accumulator[question.id] = question.answers.map(answer => ({
      ...answer,
      type: question.type
    }));
    return accumulator;
  }, {});

  return questions.map(question => {
    const { visible_dependency_on_question, visible_dependency_on_option } = question;
    const dependencyAnswers = allAnswer[visible_dependency_on_question];

    // 依據 qustion 的相依選項是否有被勾選，填入 visible
    let questionVisible = false;
    if (
      !visible_dependency_on_question ||
      dependencyAnswers?.some(
        answer =>
          answer.type === "checkbox" &&
          parseInt(answer.value, 10) === parseInt(visible_dependency_on_option, 10)
      )
    ) {
      questionVisible = true;
    }

    return {
      ...question,
      visible: questionVisible,
      options: question.options.map(option => {
        // 將 value 轉換成 form 表單的值
        let value = null;
        let additional_note = null;
        if (question.type === "checkbox") {
          const answer = allAnswer[question.id].find(
            answer => parseInt(answer.value, 10) === parseInt(option.id, 10)
          );

          value = answer ? true : false;
          additional_note = answer ? answer.additional_note : null;
        }

        if (question.type === "text") {
          value = allAnswer[question.id][0]?.value ? allAnswer[question.id][0].value : null;
        }

        return {
          ...option,
          type: question.type,
          value,
          additional_note,
          question_id: question.id,
          question_visible: questionVisible,
          question_is_required: question.is_required
        };
      })
    };
  });
};

// 題目相依排序
const sortQuestionByDependency = questions => {
  let sortedQuestions = [];

  questions
    .filter(q => !q.visible_dependency_on_option)
    .sort(sortedByQuestionPriority)
    .forEach(question => {
      sortedQuestions.push(question);

      question.options.forEach(option => {
        // 加入相依題目在被相依題目的下面
        questions
          .filter(q => q.visible_dependency_on_option === option.id)
          .sort(sortedByQuestionPriority)
          .forEach(q => sortedQuestions.push(q));
      });
    });

  return sortedQuestions;
};

// 整理題目相依資料
export const appendDependencyQuestions = (survey, sections) => {
  const allDependencyQuestions = flatten(
    sections.map(section =>
      survey[section]
        ? survey[section]
            .map((question, index) => ({ ...question, index }))
            .filter(question => question.visible_dependency_on_option)
        : []
    )
  );

  return allDependencyQuestions.reduce((accumulator, dependencyQuestion) => {
    const depends = accumulator[dependencyQuestion.visible_dependency_on_option];
    if (!depends) {
      accumulator[dependencyQuestion.visible_dependency_on_option] = [];
    }
    accumulator[dependencyQuestion.visible_dependency_on_option].push(dependencyQuestion);

    return accumulator;
  }, {});
};

export const constructionVisitSurveyActions = {
  /**
   * 覆寫作業單識別值區塊資料
   *
   * @param {object} survey - 作業單資訊
   */
  setSurveyIdentity: survey => {
    return dispatch => {
      const isRecordData = !!survey.uuid;
      dispatch({
        type: constructionVisitSurveyConstants.SET_SURVEY_IDENTITY,
        surveyIdentity: {
          // record 資料無 uuid 表示為預約單資料
          reservation_id: isRecordData ? null : survey.id,
          uuid: survey.uuid,
          receipt_number: survey.receipt_number,
          reservation_created_at: isRecordData ? null : survey.created_at,
          receptionists: survey.receptionists
        }
      });
    };
  },
  /**
   * 清空作業單識別值區塊資料
   *
   * @param {boolean} forceCreateNewStatus - 是否強制回傳新狀態（以重新 render 相關元件）
   */
  clearSurveyIdentity: (forceCreateNewStatus = false) => {
    return dispatch => {
      dispatch({
        type: constructionVisitSurveyConstants.RESET_SURVEY_IDENTITY,
        forceCreateNewStatus
      });
    };
  },
  /**
   * 設定目前處理中的問卷區塊
   *
   * @param {object} survey - 賞屋作業單資料
   * @param {boolean} isSetDefaultSurvey - 是否為重新載入預設問卷
   * @param {string} type - 更新問卷區塊
   */
  setSurvey: (survey, isSetDefaultSurvey = false, type = CONSTRUCTION_VISIT_SURVEY_TYPE.ALL) => {
    return dispatch => {
      // 同步更新 identify 資料
      if (isSetDefaultSurvey) {
        dispatch(constructionVisitSurveyActions.clearSurveyIdentity());
      } else {
        dispatch(constructionVisitSurveyActions.setSurveyIdentity(survey));
      }

      const { defaultSurvey } = survey;

      const transferedSurvey = {
        ...survey,
        // 若是設定成預設問卷，則檢查 defaultSurvey 是否有抓到資料，若無，表示未設定預設問卷
        isDefaultSurveyExist: isSetDefaultSurvey ? !!defaultSurvey : true,
        profileFinished: survey?.profile_finished_at ? true : false,
        feedbackFinished: survey?.feedback_finished_at ? true : false
      };

      if (type === CONSTRUCTION_VISIT_SURVEY_TYPE.ALL) {
        dispatch(constructionVisitSurveyActions.setProfileSurvey(transferedSurvey));
        dispatch(constructionVisitSurveyActions.setFeedbackSurvey(transferedSurvey));
        dispatch(constructionVisitSurveyActions.setTrackSurvey(transferedSurvey));
      } else {
        switch (type) {
          case CONSTRUCTION_VISIT_SURVEY_TYPE.PROFILE:
            dispatch(constructionVisitSurveyActions.setProfileSurvey(transferedSurvey));
            break;
          case CONSTRUCTION_VISIT_SURVEY_TYPE.FEEDBACK:
            dispatch(constructionVisitSurveyActions.setFeedbackSurvey(transferedSurvey));
            break;
          case CONSTRUCTION_VISIT_SURVEY_TYPE.TRACK:
            dispatch(constructionVisitSurveyActions.setTrackSurvey(transferedSurvey));
            break;
          default:
            break;
        }
      }
    };
  },
  /**
   * 設定顧客基本資料問卷區塊
   * 
   * @param {object} survey - 問卷資料
   */
  setProfileSurvey: survey => {
    const {
      telephone,
      username,
      home_phone,
      email,
      address,
      construction_visit_signature,
      construction_visit_confirm
    } = survey;

    let transferedSurvey = {
      ...survey,
      telephone: telephone || "",
      username: username || "",
      home_phone: home_phone || "",
      email: email || "",
      address: address || "",
      construction_visit_signature: construction_visit_signature || "",
      construction_visit_confirm: construction_visit_confirm || false,
      profile: sortQuestionByDependency(transferAnswerInSurveyQuestions(survey.profile)),
      profileFinished: survey?.profile_finished_at ? true : false,
      feedbackFinished: survey?.feedback_finished_at ? true : false
    };

    // 因 appendDependencyQuestions 需要紀錄題目的 index，故需先透過 sortQuestionByDependency 確定好題目的 index，再產生題目選項相依題目
    transferedSurvey = {
      ...transferedSurvey,
      optionDependencies: appendDependencyQuestions(transferedSurvey, ["profile"])
    };

    return {
      type: constructionVisitSurveyConstants.SET_SURVEY,
      surveyType: CONSTRUCTION_VISIT_SURVEY_TYPE.PROFILE,
      survey: transferedSurvey
    };
  },
  /**
   * 設定顧客回饋問卷區塊
   * 
   * @param {object} survey - 問卷資料
   */
  setFeedbackSurvey: survey => {
    let transferedSurvey = {
      ...survey,
      feedback: sortQuestionByDependency(transferAnswerInSurveyQuestions(survey.feedback))
    };

    // 因 appendDependencyQuestions 需要紀錄題目的 index，故需先透過 sortQuestionByDependency 確定好題目的 index，再產生題目選項相依題目
    transferedSurvey = {
      ...transferedSurvey,
      optionDependencies: appendDependencyQuestions(transferedSurvey, ["feedback"])
    };

    return {
      type: constructionVisitSurveyConstants.SET_SURVEY,
      surveyType: CONSTRUCTION_VISIT_SURVEY_TYPE.FEEDBACK,
      survey: transferedSurvey
    };
  },
  /**
   * 設定顧客追蹤處理資料問卷區塊
   * 
   * @param {object} survey - 問卷資料
   */
  setTrackSurvey: survey => {
    const { process_note } = survey;

    let transferedSurvey = {
      ...survey,
      process_note: process_note || "",
      track: sortQuestionByDependency(transferAnswerInSurveyQuestions(survey.track)),
      profileFinished: survey?.profile_finished_at ? true : false
    };

    // 因 appendDependencyQuestions 需要紀錄題目的 index，故需先透過 sortQuestionByDependency 確定好題目的 index，再產生題目選項相依題目
    transferedSurvey = {
      ...transferedSurvey,
      optionDependencies: appendDependencyQuestions(transferedSurvey, ["track"])
    };

    return {
      type: constructionVisitSurveyConstants.SET_SURVEY,
      surveyType: CONSTRUCTION_VISIT_SURVEY_TYPE.TRACK,
      survey: transferedSurvey
    };
  },
  /**
   * 重設所有狀態
   */
  resetAll: () => {
    return {
      type: constructionVisitSurveyConstants.RESET_ALL
    };
  }
};
