export const constructionVisitConstants = {
  // 取得目前所選建案的賞屋諮詢題目
  GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_REQUEST:
    "CONSTRUCTION_VISIT_GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_REQUEST",
  GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_DONE:
    "CONSTRUCTION_VISIT_GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_DONE",
  GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_FAILURE:
    "CONSTRUCTION_VISIT_GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_FAILURE",
  RESET_GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY:
    "CONSTRUCTION_VISIT_RESET_GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY",
  // 取得作業單資料 By 作業單 UUID
  GET_VISIT_RECORD_BY_ID_REQUEST: "CONSTRUCTION_VISIT_GET_VISIT_RECORD_BY_ID_REQUEST",
  GET_VISIT_RECORD_BY_ID_DONE: "CONSTRUCTION_VISIT_GET_VISIT_RECORD_BY_ID_DONE",
  GET_VISIT_RECORD_BY_ID_FAILURE: "CONSTRUCTION_VISIT_GET_VISIT_RECORD_BY_ID_FAILURE",
  RESET_VISIT_RECORD_BY_ID: "CONSTRUCTION_VISIT_RESET_VISIT_RECORD_BY_ID",
  // 更新作業單追蹤處理資料
  UPDATE_VISIT_TRACK_SURVEY_REQUEST: "CONSTRUCTION_VISIT_UPDATE_VISIT_TRACK_SURVEY_REQUEST",
  UPDATE_VISIT_TRACK_SURVEY_DONE: "CONSTRUCTION_VISIT_UPDATE_VISIT_TRACK_SURVEY_DONE",
  // 新增作業單留言板資料相關
  INSERT_VISIT_MESSAGE_REQUEST: "CONSTRUCTION_VISIT_INSERT_VISIT_MESSAGE_REQUEST",
  INSERT_VISIT_MESSAGE_DONE: "CONSTRUCTION_VISIT_INSERT_VISIT_MESSAGE_DONE",
  // 取得作業單留言板資料相關
  GET_VISIT_MESSAGES_REQUEST: "CONSTRUCTION_VISIT_GET_VISIT_MESSAGES_REQUEST",
  GET_VISIT_MESSAGES_SUCCESS: "CONSTRUCTION_VISIT_GET_VISIT_MESSAGES_SUCCESS",
  GET_VISIT_MESSAGES_FAILURE: "CONSTRUCTION_VISIT_GET_VISIT_MESSAGES_FAILURE",
  RESET_VISIT_MESSAGES: "CONSTRUCTION_VISIT_RESET_VISIT_MESSAGES",
  // 重設所有資料
  RESET_ALL: "CONSTRUCTION_VISIT_RESET_ALL"
};
