import { createMuiTheme } from "@material-ui/core/styles";

const hylTheme = {
  primaryColor: "#133146",
  secondaryColor: "#A67B4E",
  successColor: "#59A072",
  errorColor: "#B96161"
};

const theme = createMuiTheme({
  // 可以自訂 style 相關的變數，供各子元件統一使用
  hyl: {
    ...hylTheme
  },
  /**
   * 可複寫 Material UI 元件的樣式
   * https://material-ui.com/customization/palette/
   **/
  palette: {
    primary: {
      main: hylTheme.primaryColor
    },
    secondary: {
      main: hylTheme.secondaryColor
    },
    Error: {
      main: hylTheme.errorColor
    },
    Success: {
      main: hylTheme.successColor
    }
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  /**
   * 可複寫 Material UI 元件的屬性預設值 
   * https://material-ui.com/customization/globals/#default-props
   **/
  props: {
    MuiFormControl: {
      variant: "outlined",
      fullWidth: true
    },
    MuiTextField: {
      variant: "outlined",
      fullWidth: true
    },
    MuiLink: {
      underline: "none"
    }
  },
  overrides: {
    /**
     * 可加入全域樣式 
     * https://material-ui.com/customization/globals/#global-css
     **/ 
    MuiCssBaseline: {
      "@global": {
      }
    }
  }
});

export default theme;
