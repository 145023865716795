import { constructionReservationContstans } from "../constants";

const initState = {
  reservations: {
    loading: false,
    total: null,
    items: null,
    conditions: null,
    error: null
  },
  assignReceptionist: {
    loading: false,
    error: null
  }
};

export const constructionReservations = (state = initState, action) => {
  switch (action.type) {
    case constructionReservationContstans.GET_ALL_REQUEST:
      return {
        ...state,
        reservations: {
          ...initState.reservations,
          loading: true
        }
      };
    case constructionReservationContstans.GET_ALL_SUCCESS:
      return {
        ...state,
        reservations: {
          ...initState.reservations,
          loading: false,
          items: action.items,
          total: action.total,
          conditions: action.conditions,
          unassigned_total: action.unassigned_total
        }
      };
    case constructionReservationContstans.GET_ALL_FAILURE:
      return {
        ...state,
        reservations: {
          ...initState.reservations,
          loading: false,
          error: action.error
        }
      };
    case constructionReservationContstans.RESET_ITEMS: {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          items: null,
          total: null
        }
      };
    }
    case constructionReservationContstans.RESET_GET_ALL:
      return {
        ...state,
        reservations: {
          ...initState.reservations
        }
      };
    case constructionReservationContstans.ASSIGN_RECEPTIONIST_REQUEST:
      return {
        ...state,
        assignReceptionist: {
          ...initState.assignReceptionist,
          loading: true
        }
      };
    case constructionReservationContstans.ASSIGN_RECEPTIONIST_SUCCESS:
      return {
        ...state,
        assignReceptionist: {
          ...initState.assignReceptionist,
          loading: false
        }
      };
    case constructionReservationContstans.ASSIGN_RECEPTIONIST_FAILURE:
      return {
        ...state,
        assignReceptionist: {
          ...initState.assignReceptionist,
          loading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
