import React, { useMemo, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ApartmentOutlinedIcon from "@material-ui/icons/ApartmentOutlined";
import LoopIcon from "@material-ui/icons/Loop";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import { useDispatch, useSelector } from "react-redux";
import { authActions, modalActions } from "../../actions";
import { history, router, secureStroge, storgeKeys } from "../../utils";
import logoUrl from "../../assets/check/logo.png";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import Link from "@material-ui/core/Link";

const Logo = withStyles(theme => ({
  root: {
    display: "inline-block",
    width: "120px",
    height: "100%",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      width: "180px"
    }
  },
  img: {
    objectFit: "cover",
    width: "100%"
  }
}))(({ classes }) => {
  const handleLogoClick = () => {
    history.push(router.getPath("Admin.Home"));
  };

  return (
    <div className={classes.root} onClick={handleLogoClick}>
      <img src={logoUrl} alt="logo" className={classes.img} />
    </div>
  );
});

const useUserInfoStyles = makeStyles(theme => ({
  root: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center"
  },
  welcomeLabel: {
    marginRight: props => (props.currentSelectedConstructionUUID ? 0 : theme.spacing(1))
  },
  currentSelectedConstructionButtonRoot: {
    verticalAlign: "inherit"
  },
  userNameRoot: {
    fontWeight: 600
  },
  roleTitleRoot: {
    marginLeft: theme.spacing(1)
  },
  avatarRoot: {
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main
  },
  dialogAvatarRoot: {
    backgroundColor: blue[100],
    color: blue[600]
  },
  switchConstructionDialogPaper: {
    minWidth: "250px"
  },
  menuListRoot: {
    display: "flex",
    flexDirection: "column",
    // minHeight: "350px",
    minWidth: "250px"
  },
  logoutMenuItem: {
    color: theme.palette.error.main,
    "& .MuiSvgIcon-root": {
      color: theme.palette.error.main
    }
  }
}));

const roleTitleAttrMap = {
  salesProjectManager: {
    title: "專案",
    titleColor: "#B96161",
    order: 1,
  },
  salespeople: {
    title: "銷售",
    titleColor: "#59A072",
    order: 0,
  },
  HylProjectManager: {
    title: "公司專案",
    titleColor: "#B96161",
    order: 2,
  }
};

const UserInfo = props => {
  const classes = useUserInfoStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSelectConstructionDialog, setOpenSelectConstructionDialog] = useState(false);
  const dispatch = useDispatch();

  const {
    userInfo: { username = "", telephone = "", headshot = "", constructions_sales_roles = [] },
    currentSelectedConstructionUUID
  } = props;

  const constructionSalesRoles = useMemo(() => {
    return constructions_sales_roles.filter(constructionRole => {
      const { roles } = constructionRole;
      return roles.length > 0;
    });
  }, [constructions_sales_roles]);

  const currentSelectedConstruction = constructions_sales_roles.find(
    item => item.construction_uuid === currentSelectedConstructionUUID
  );

  const currentSelectedConstructionRole = currentSelectedConstruction?.roles.sort(
    (item1, item2) => {
      const item1Order = roleTitleAttrMap[item1.key].order;
      const item2Order = roleTitleAttrMap[item2.key].order;
      // 排序結果為由大到小
      return item2Order - item1Order;
    }
  )[0];

  const currentSelectedConstructionRoleAttr =
    roleTitleAttrMap[currentSelectedConstructionRole?.key] || ({});

  const {
    title: currentSelectedConstructionRoleTitle,
    titleColor: currentSelectedConstructionRoleTitleColor
  } = currentSelectedConstructionRoleAttr;

  const isExistMultipleConstructionPriciple = constructions_sales_roles.length > 1;

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleGoConstructionReservation = () => {
    history.push(router.getPath("ReserveConstruction", { constructionUUID: null }));
  };

  const handleLogout = () => {
    closeMenu();
    dispatch(
      modalActions.show("CONFIRM", {
        title: "確認",
        content: "是否確認登出?",
        okText: "確定",
        onOk: () => {
          dispatch(authActions.logout());
        }
      })
    );
  };

  const handleSwitchConstrurction = (construction, isCurrentSelectedConstruction) => {
    if (!isCurrentSelectedConstruction) {
      dispatch(authActions.switchConstruction(construction.construction_uuid));
    }
    setOpenSelectConstructionDialog(false);
  };

  return (
    <div className={classes.root}>
      <span>
        {currentSelectedConstructionUUID && (
          <ButtonBase
            disableRipple={!isExistMultipleConstructionPriciple}
            onClick={
              isExistMultipleConstructionPriciple
                ? () => setOpenSelectConstructionDialog(true)
                : null
            }
            classes={{ root: classes.currentSelectedConstructionButtonRoot }}
          >
            <ApartmentOutlinedIcon />
            <Typography variant="subtitle1" style={{ margin: "0px 5px" }}>
              {currentSelectedConstruction?.construction_name}
            </Typography>
            {isExistMultipleConstructionPriciple && <ArrowDropDownOutlinedIcon />}
          </ButtonBase>
        )}
      </span>
      <span>
        <IconButton color="secondary" onClick={handleOpenMenu}>
          <Avatar alt={username} src={headshot} classes={{ root: classes.avatarRoot }} />
        </IconButton>
        <Menu
          autoFocus={false}
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={closeMenu}
          classes={{ list: classes.menuListRoot }}
        >
          {currentSelectedConstruction && (
            <ListItem style={{ justifyContent: "center" }}>
              <>
                <ApartmentOutlinedIcon />
                <Typography
                  color="secondary"
                  style={{ fontSize: "1.3rem", fontWeight: "600", marginLeft: "3px" }}
                >
                  {currentSelectedConstruction?.construction_name}
                </Typography>
                <Chip
                  size="small"
                  label={currentSelectedConstructionRoleTitle}
                  style={{
                    marginLeft: "8px",
                    borderRadius: "5px",
                    background: currentSelectedConstructionRoleTitleColor,
                    color: "white"
                  }}
                />
              </>
            </ListItem>
          )}
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={username} src={headshot} classes={{ root: classes.avatarRoot }} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="subtitle1" classes={{ root: classes.userNameRoot }}>
                  {username}
                </Typography>
              }
              secondary={
                <Typography variant="subtitle2" style={{ color: "#B9B9B9", fontWeight: "400" }}>
                  {telephone}
                </Typography>
              }
            />
          </ListItem>
          <Divider component="li" />
          {isExistMultipleConstructionPriciple && (
            <MenuItem
              onClick={() => {
                closeMenu();
                setOpenSelectConstructionDialog(true);
              }}
            >
              <ListItemIcon>
                <LoopIcon />
              </ListItemIcon>
              <ListItemText>切換建案</ListItemText>
            </MenuItem>
          )}
          <MenuItem onClick={handleGoConstructionReservation}>
            <ListItemIcon>
              <OpenInNewIcon />
            </ListItemIcon>
            <ListItemText>前往預約</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleLogout} className={classes.logoutMenuItem}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText>登出</ListItemText>
          </MenuItem>
        </Menu>
      </span>
      <Dialog
        onClose={() => setOpenSelectConstructionDialog(false)}
        aria-labelledby="select-constructio-dialog-title"
        open={openSelectConstructionDialog}
        maxWidth="xs"
        classes={{ paper: classes.switchConstructionDialogPaper }}
      >
        <DialogTitle id="select-constructio-dialog-title">切換建案</DialogTitle>
        <DialogContent dividers>
          <List>
            {constructionSalesRoles.map(construction => {
              const isCurrentSelectedConstruction =
                currentSelectedConstructionUUID === construction.construction_uuid;
              const text = (
                <span>
                  {construction.construction_name}
                  {isCurrentSelectedConstruction ? (
                    <span style={{ fontSize: "0.875rem", color: "#B9B9B9" }}>（當前所在建案）</span>
                  ) : null}
                </span>
              );
              return (
                <ListItem
                  button
                  onClick={() => {
                    handleSwitchConstrurction(construction, isCurrentSelectedConstruction);
                  }}
                  key={construction.construction_uuid}
                  // disabled={currentSelectedConstruction}
                >
                  <ListItemAvatar>
                    <Avatar classes={{ root: classes.dialogAvatarRoot }}>
                      <ApartmentOutlinedIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={text} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const EmptyUserInfoMenu = withStyles(them => ({
  root: {
    marginLeft: "auto"
  }
}))(({ classes }) => {
  return (
    <div className={classes.root}>
      <Link
        onClick={() => {
          history.push(router.getPath("ReserveConstruction", { constructionUUID: null }));
        }}
        style={{ color: "white", cursor: "pointer" }}
      >
        立即前往預約
      </Link>
    </div>
  );
});

const Header = withStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  }
}))(({ classes }) => {
  const { userInfo } = useSelector(state => state.auth);
  const { selectedConstruction } = useSelector(state => state.auth);

  return (
    <AppBar position="relative">
      <Toolbar variant="regular" disableGutters={false}>
        <div className={classes.root}>
          <Logo />
          {userInfo && secureStroge.get(storgeKeys.userAuth) ? (
            <UserInfo userInfo={userInfo} currentSelectedConstructionUUID={selectedConstruction} />
          ) : (
            <EmptyUserInfoMenu />
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
});

export default Header;
