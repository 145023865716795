import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import ModalContainer from "../components/Modal/ModalContainer";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../themes/theme";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  "@global": {
    // 避免 reCaptcha 被其他 overlay 蓋到
    ".grecaptcha-badge": {
      zIndex: theme.zIndex.drawer - 1
    }
  }
}));

const ScrollTopButton = withStyles(theme => ({
  root: {
    position: "fixed",
    bottom: "15%",
    right: theme.spacing(2),
    zIndex: theme.zIndex.appBar - 1
  },
  button: {
    backgroundColor: "#cecece"
  }
}))(({ classes }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 64
  });

  const handleClick = event => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "center"
    });
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        <Fab size="small" aria-label="scroll back to top" className={classes.button}>
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  );
});

const Layout = () => {
  const classes = useStyles();
  
  return (
    <ThemeProvider theme={theme}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY} language="zh-TW">
        <div className={classes.root}>
          <Header />
          <Content />
          <Footer />
          <ScrollTopButton />
          <ModalContainer />
        </div>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
};

export default Layout;
