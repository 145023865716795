import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useDispatch } from "react-redux";
import { constructionActions } from "../actions";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import HeaderLogo from "./HeaderLogo";
import HeaderMenus from "./HeaderMenus";

// 當滾動未到最頂部時，AppBar 顯示下陰影
const ElevationScroll = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
};

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative"
  }
}));

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(constructionActions.getConsructions());
  }, []);

  return (
    <>
      <ElevationScroll>
        <AppBar position="relative">
          <Container maxWidth={theme.hyl.maxContentWidthBreakpoint} className={classes.container}>
            <Toolbar variant="regular" disableGutters={true}>
              <HeaderLogo />
              <HeaderMenus />
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

export default Header;
