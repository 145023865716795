import { authConstants } from "../constants";
import { secureStroge, storgeKeys } from "../utils";

const initState = {
  userInfo: null,
  userPermissions: null,
  selectedConstruction: null,
  isSalesProjectManagerOnSelectedConstruction: false,
  login: {
    loading: false,
    error: null
  }
};

const checkSalesProjectManager = (userInfo, selectedConstruction) => {
  const currentConstruction = userInfo?.constructions_sales_roles?.find(
    c => c.construction_uuid === selectedConstruction
  );

  return (
    currentConstruction &&
    currentConstruction.roles.some(role => ["salesProjectManager", "HylProjectManager"].includes(role.key))
  );
};

export const auth = (state = initState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST: {
      return {
        ...state,
        login: {
          ...initState.login,
          loading: true
        }
      };
    }
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...initState.login,
          loading: false
        },
        userInfo: action.userInfo,
        userPermissions: action.userInfo.permissions,
        selectedConstruction: action.selectedConstruction,
        isSalesProjectManagerOnSelectedConstruction: checkSalesProjectManager(
          action.userInfo,
          action.selectedConstruction
        )
      };
    case authConstants.REFRESH_LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: action.userInfo,
        userPermissions: action.userInfo.permissions,
        isSalesProjectManagerOnSelectedConstruction: checkSalesProjectManager(
          action.userInfo,
          state.selectedConstruction
        )
      };
    case authConstants.SELECT_CONSTRUCTION: {
      return {
        ...state,
        selectedConstruction: action.selectedConstruction,
        isSalesProjectManagerOnSelectedConstruction: checkSalesProjectManager(
          state.userInfo,
          action.selectedConstruction
        )
      };
    }
    case authConstants.LOGING_FAILURE: {
      return {
        ...state,
        login: {
          ...initState.login,
          loading: false,
          error: action.error
        }
      };
    }
    case authConstants.LOGOUT_SUCCESS:
      secureStroge.remove(storgeKeys.auth);
      return {
        ...state,
        userInfo: null,
        userPermissions: null,
        selectedConstruction: null,
        isSalesProjectManagerOnSelectedConstruction: false
      };
    case authConstants.CLEAR_LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          error: null
        }
      };
    default:
      return state;
  }
};
