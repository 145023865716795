import React from "react";
import ContentRouter from "./ContentRouter";
import { withStyles } from "@material-ui/core";

const Content = withStyles(theme => ({
  root: {
    backgroundColor: "#EEEEEE",
    flex: "1 1 auto",
    display: "flex",
    // overflow: "hidden"
  }
}))(({ classes }) => {
  return (
    <main className={classes.root}>
      <ContentRouter />
    </main>
  );
});

export default Content;
