import { combineReducers } from "redux";
import { mask } from "./mask.reducer";
import { modal } from "./modal.reducer";
import { auth } from "./auth.reducer";
import { construction } from "./construction.reducer";
import { constructionVisit } from "./constructionVisit.reducer";
import { constructionVisitSurvey } from "./constructionVisitSurvey.reducer";
import { constructionVisitFeedback } from "./constructionVisitFeedback.reducer";
import { constructionReservations } from "./constructionReservations.reducer";
import { alert } from "./alert.reducer";
import { constructionVisitRecords } from "./constructionVisitRecords.reducer";

const rootReducer = combineReducers({
  mask,
  modal,
  alert,
  auth,
  construction,
  constructionVisit,
  constructionVisitSurvey,
  constructionVisitFeedback,
  constructionReservations,
  constructionVisitRecords
  // todo ...
});

export default rootReducer;
