import { constructionConstants } from "../constants";

const initState = {
  list: {},
  selectedItem: null,
  salesList: {
    loading: false,
    data: null,
    error: null
  },
  salesListForQueryForm: {
    loading: false,
    data: null,
    error: null
  }
};

export const construction = (state = initState, action) => {
  switch (action.type) {
    case constructionConstants.GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          loading: true
        }
      };
    case constructionConstants.GET_LIST_SUCCESS:
      let items = [
        ...action.items.filter(item => item.status === "coming_soon"),
        ...action.items.filter(item => item.status === "selling"),
        ...action.items.filter(item => item.status === "sellout")
      ];
      return {
        ...state,
        list: {
          loading: false,
          items: items
        }
      };
    case constructionConstants.GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          loading: false,
          error: action.error
        }
      };
    case constructionConstants.RESET_LIST_SUCCESS:
      return {
        ...state,
        list: {}
      };
    case constructionConstants.SELECT_ITEM_BY_ID: {
      const { items } = state.list;
      let selectedItem = items?.find(item => item.id === action.constructionId);
      return {
        ...state,
        selectedItem: selectedItem ? selectedItem : null
      };
    }
    case constructionConstants.SELECT_ITEM_BY_UUID: {
      const { items } = state.list;
      let selectedItem = items?.find(item => item.uuid === action.constructionUUID);
      return {
        ...state,
        selectedItem: selectedItem ? selectedItem : null
      };
    }
    case constructionConstants.CLEAR_ITEM:
      return {
        ...state,
        selectedItem: null
      };
    case constructionConstants.GET_SALES_PERSON_LIST_REQUEST:
      return {
        ...state,
        salesList: {
          ...initState.salesList,
          loading: true
        }
      };
    case constructionConstants.GET_SALES_PERSON_LIST_SUCCESS:
      return {
        ...state,
        salesList: {
          ...initState.salesList,
          data: action.items
        }
      };
    case constructionConstants.GET_SALES_PERSON_LIST_FAILURE:
      return {
        ...state,
        salesList: {
          ...initState.salesList,
          error: action.error
        }
      };

    case constructionConstants.GET_SALES_PERSON_LIST_FOR_QUERY_FORM_REQUEST:
      return {
        ...state,
        salesListForQueryForm: {
          ...initState.salesListForQueryForm,
          loading: true
        }
      };
    case constructionConstants.GET_SALES_PERSON_LIST_FOR_QUERY_FORM_SUCCESS:
      return {
        ...state,
        salesListForQueryForm: {
          ...initState.salesListForQueryForm,
          data: action.items
        }
      };
    case constructionConstants.RESET_SALES_PERSON_LIST_FOR_QUERY_FORM:
      return {
        ...state,
        salesListForQueryForm: {
          ...initState.salesListForQueryForm,
          error: action.error
        }
      };
    default:
      return state;
  }
};
