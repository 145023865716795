import { ajax } from "../utils";

const FROM = "web.official";

const fakeUserInfoForDev = {
  uuid: "50e6f1b0-6f41-11ea-a4b9-e75f97a53c76",
  username: "吳小毅",
  token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJoeWwtand0Iiwic3ViIjp7InVzZXJJRCI6ODMsInVzZXJVVUlEIjoiNTBlNmYxYjAtNmY0MS0xMWVhLWE0YjktZTc1Zjk3YTUzYzc2IiwidXNlck5BTUUiOiJcdWZmMDhcdTVjMGZcdTRlNTZcdWZmMDlcdTRmNTVcdTczODlcdTY2MjUiLCJyb2xlcyI6WyJnZW5lcmFsVXNlciIsImhvdXNlaG9sZCIsInN0b3JlU3RhZmYiLCJzdG9yZURlbGl2ZXJ5RHJpdmVyIl19LCJpYXQiOjE2MTE2Mjk1Mjd9.eD5XOioBIey5jzNOXRPGIA8MLdQ2JZVrCGAissI1bHI",
  telephone: "0929139896",
  headshot: "https://i.imgur.com/MVR7EtS.png",
  // headshot: null,
  construction_sales_principle: [
    {
      uuid: "son_uuid",
      name: "森之道",
      roles: [
        { key: "salesProjectManager", name: "銷售專案" },
        { key: "salespeople", name: "銷售人員" }
      ]
    },
    {
      uuid: "hua_uuid",
      name: "華悅",
      roles: [{ key: "salespeople", name: "銷售人員" }]
    }
  ]
};

export const authService = {
  /**
   * 登入
   *
   * @param {string} loginInfo.telephone - The telephone of user.
   * @param {string} loginInfo.password - The password of user.
   */
  login: async loginInfo => {
    try {
      // return new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     resolve(fakeUserInfoForDev);
      //     // reject({ message: "登入失敗" });
      //   }, 500);
      // });
      const { telephone, password, token } = loginInfo;

      // const { data } = await ajax.post("auth/login", {
      //   telephone,
      //   password,
      //   source: SOURCE
      // });
      const response = await ajax.post("auth/login", {
        telephone,
        password,
        recaptcha_token: token,
        from: FROM
      });
      return response.data;
    } catch (error) {
      return Promise.reject({ ...error, message: error.serverMessage,  });
    }
  },
  /**
   * 檢查登入
   *
   * @param {string} uuid - The uuid of user.
   */
  check:  async uuid => {
    try {
      // return new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     resolve(fakeUserInfoForDev);
      //     // reject({ message: "登入失敗" });
      //   }, 500);
      // });

      const { data } = await ajax.post(
        "auth/authenticate",
        {
          uuid,
          from: FROM
        },
        {
          isWithToken: true
        }
      );
      return data;
    } catch (error) {
      return Promise.reject({ message: error.serverMessage });
    }
  }
};
