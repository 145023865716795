import { constructionVisitRecordsConstants } from "../constants";
import moment from "moment";
import { trim } from "lodash";

const initState = {
  /**
   * 賞屋作業單紀錄列表
   */
  records: {
    loading: false,
    total: null,
    items: null,
    conditions: null,
    error: null
  },
  /**
   * 指派銷售人員 & 移除銷售人員
   */
  settingReceptionist: {
    loading: false,
    error: null
  },
  /**
   * 設定忽略 & 移除忽略
   */
  settingIgnore: {
    loading: false,
    error: null
  },
  /**
   * 設定約定接待時間
   */
  settingAppointmentAt: {
    loading: false,
    error: null
  },
  /**
   * 取消約定接待時間
   */
  cancelAppointmentAt: {
    loading: false,
    error: null
  }
};

export const constructionVisitRecords = (state = initState, action) => {
  switch (action.type) {
    // -------- 取得賞屋作業單列表 START --------
    case constructionVisitRecordsConstants.GET_ALL_REQUEST:
      return {
        ...state,
        records: {
          ...initState.records,
          loading: true
        }
      };
    case constructionVisitRecordsConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        records: {
          ...initState.records,
          loading: false,
          items: action.items,
          total: action.total,
          conditions: action.conditions
        }
      };
    case constructionVisitRecordsConstants.GET_ALL_FAILURE:
      return {
        ...state,
        records: {
          ...initState.records,
          error: action.error
        }
      };
    case constructionVisitRecordsConstants.RESET_ITEMS: {
      return {
        ...state,
        records: {
          ...state.records,
          items: null,
          total: null
        }
      };
    }
    case constructionVisitRecordsConstants.RESET_GET_ALL:
      return {
        ...state,
        records: {
          ...initState.records
        }
      };
    case constructionVisitRecordsConstants.REPLACE_ITEM: {
      const { id, newItem } = action;
      return {
        ...state,
        records: {
          ...state.records,
          items: state.records.items.map(item =>
            item.id === id ? { ...newItem, _highlight: true } : item
          )
        }
      };
    }
    case constructionVisitRecordsConstants.CLEAR_ITEM_HIGHLIGHT: {
      const { id } = action;
      return {
        ...state,
        records: {
          ...state.records,
          items: state.records.items?.map(item =>
            item.id === id ? { ...item, _highlight: false } : item
          )
        }
      };
    }
    case constructionVisitRecordsConstants.REPLACE_KEEP_TRACKING_INFO: {
      const { id, info } = action;
      return {
        ...state,
        records: {
          ...state.records,
          items: state.records.items?.map(item => (item.id === id ? { ...item, ...info } : item))
        }
      };
    }
    case constructionVisitRecordsConstants.REPLACE_VISIT_AGAIN_INFO: {
      const { uuid, info } = action;
      return {
        ...state,
        records: {
          ...state.records,
          items: state.records.items?.map(item => (item.uuid === uuid ? { ...item, ...info } : item))
        }
      };
    }
    case constructionVisitRecordsConstants.REPLACE_ITEM_LAST_MESSAGE:
      const { constructionVisitUUID, messages } = action;
      let lastMessages = {};
      if (messages) {
        // 依 create 時間排序
        messages.sort((m1, m2) => {
          const moment1 = moment(m1.created_at);
          const moment2 = moment(m2.created_at);
          if (moment1 > moment2) return 1;
          else if (moment1 < moment2) return -1;
          else return 0;
        });

        // 取新一筆 message
        if (messages.length > 0) {
          lastMessages = messages[messages.length - 1];
        }
      }

      let newItems = state.records.items;
      const { username, headshot, content, created_at, type } = lastMessages;

      if (state.records.items) {
        newItems = state.records.items.map(item => {
          if (item.uuid === constructionVisitUUID) {
            return {
              ...item,
              message_type: type,
              message_username: username,
              message_user_headshot: headshot,
              message_content: content,
              message_created_at: created_at
            };
          } else {
            return item;
          }
        });
      }

      return {
        ...state,
        records: {
          ...state.records,
          items: newItems
        }
      };
    // -------- 取得賞屋作業單列表 END --------

    // -------- 指派銷售人員 & 移除銷售人員 START --------
    case constructionVisitRecordsConstants.SETTING_VISIT_RECPTIONIST_REQUEST:
      return {
        ...state,
        settingReceptionist: {
          ...initState.settingReceptionist,
          loading: true
        }
      };
    case constructionVisitRecordsConstants.SETTING_VISIT_RECPTIONIST_SUCCESS:
      return {
        ...state,
        settingReceptionist: {
          ...initState.settingReceptionist,
          loading: false
        }
      };
    case constructionVisitRecordsConstants.SETTING_VISIT_RECPTIONIST_FAILURE:
      return {
        ...state,
        settingReceptionist: {
          ...initState.settingReceptionist,
          error: action.error
        }
      };
    // -------- 指派銷售人員 & 移除銷售人員  END --------

    // -------- 設定忽略 & 移除忽略 START --------
    case constructionVisitRecordsConstants.IGNORE_VISIT_RECORD_REQUEST:
      return {
        ...state,
        settingIgnore: {
          ...initState.settingIgnore,
          loading: true
        }
      };
    case constructionVisitRecordsConstants.IGNORE_VISIT_RECORD_SUCCESS:
      return {
        ...state,
        settingIgnore: {
          ...initState.settingIgnore,
          loading: false
        }
      };
    case constructionVisitRecordsConstants.IGNORE_VISIT_RECORD_FAILURE:
      return {
        ...state,
        settingIgnore: {
          ...initState.settingIgnore,
          error: action.error
        }
      };
    // -------- 設定忽略 & 移除忽略  END --------

    // -------- 設定約定接待時間 START --------
    case constructionVisitRecordsConstants.SETTING_APPOINTMENT_AT_REQUEST:
      return {
        ...state,
        settingAppointmentAt: {
          ...initState.settingAppointmentAt,
          loading: true
        }
      };
    case constructionVisitRecordsConstants.SETTING_APPOINTMENT_AT_SUCCESS:
      return {
        ...state,
        settingAppointmentAt: {
          ...initState.settingAppointmentAt,
          loading: false
        }
      };
    case constructionVisitRecordsConstants.SETTING_APPOINTMENT_AT_FAILURE:
      return {
        ...state,
        settingAppointmentAt: {
          ...initState.settingAppointmentAt,
          error: action.error
        }
      };
    // -------- 設定約定接待時間  END --------

    // -------- 取消約定接待時間 START --------
    case constructionVisitRecordsConstants.CANCEL_APPOINTMENT_AT_REQUEST:
      return {
        ...state,
        cancelAppointmentAt: {
          ...initState.cancelAppointmentAt,
          loading: true
        }
      };
    case constructionVisitRecordsConstants.CANCEL_APPOINTMENT_AT_SUCCESS:
      return {
        ...state,
        cancelAppointmentAt: {
          ...initState.cancelAppointmentAt,
          loading: false
        }
      };
    case constructionVisitRecordsConstants.CANCEL_APPOINTMENT_AT_FAILURE:
      return {
        ...state,
        cancelAppointmentAt: {
          ...initState.cancelAppointmentAt,
          error: action.error
        }
      };
    // -------- 取消約定接待時間  END --------

    case constructionVisitRecordsConstants.RESET_APPOINTMENT:
      return {
        ...state,
        settingAppointmentAt: {
          ...initState.settingAppointmentAt
        },
        cancelAppointmentAt: {
          ...initState.cancelAppointmentAt
        }
      };

    default:
      return state;
  }
};
