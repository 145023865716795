import { alertConstants } from "../constants";

export const alertActions = {
  /**
   * 顯示 Alert.
   * @param {string} message - 訊息.
   * @param {string} type - 類型（success, error）
   */
  show(message, alertType = "success") {
    return {
      type: alertConstants.SHOW,
      message,
      alertType
    };
  },
  hide() {
    return {
      type: alertConstants.HIDE
    };
  }
};
