import { useEffect } from "react";
import { docTitle } from "../utils";
import { useHistory, useLocation } from "react-router-dom";

// 監聽 route 變化，並自動查找 route 表對應的名稱
const useRouteTitle = () => {
  const history = useHistory();

  useEffect(() => {
    return history.listen(location => {
      docTitle.refreshTitleByRoute(location);
    });
  }, [history]);

  const location = useLocation();

  useEffect(() => {
    docTitle.refreshTitleByRoute(location);
  }, []);
};

export default useRouteTitle;
