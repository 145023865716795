import { matchPath, generatePath } from "react-router-dom";
import { common } from "./common.util";
import { get, findLastKey } from "lodash";

/**
 * 功能路由匹配表
 * @description 開發新功能時，自動加上對應頁面連結
 */
const routerMatcher = {
  ReserveConstruction: {
    path: "/ReserveConstruction/:constructionUUID?",
    name: "預約賞屋"
  },
  PrivacyPolicy: {
    path: "/PrivacyPolicy",
    name: "隱私權政策"
  },
  ConstructionVisitFeedback: {
    path: "/ConstructionVisitFeedback/:visitUUID",
    name: "顧客回饋問卷"
  },
  ConstructionVisitThanks: {
    path: "/ConstructionVisitThanks/",
    name: "感謝填寫問卷"
  },
  Admin: {
    Home: {
      path: "/Admin",
      name: "後台首頁"
    },
    Login: {
      path: "/Admin/Login",
      name: "登入"
    },
    ConstructionVisit: {
      path: "/Admin/ConstructionVisit/:visitUUID?",
      name: "現訪作業"
    },
    ConstructionVisitSearch: {
      path: "/Admin/ConstructionVisitSearch",
      name: "作業查詢"
    }
  },
  NotFound: {
    path: "/NotFound",
    name: "查無資料"
  }
};

export const router = {
  /**
   * 根據索引取得頁面對應位址
   * @param {key} string - 功能權限索引
   * @param {params} object - 網址參數
   * @description 與 ContentRouter 與 SliderLayout 的頁面連結有關
   */
  getPath(key, params) {
    if (key) {
      let route = get(routerMatcher, key);
      if (route) {
        const { path } = route;
        return params ? generatePath(path, params) : path;
      }
    }
    return "/";
  },

  /**
   * 根據頁面實際位址取得索引
   * @param {path} string - 頁面實際位址
   */
  getKey(path) {
    const flattedRoutes = common.objectFlatDeep({
      target: routerMatcher,
      flatBy: value => value.path
    });
    const matchRouteKey = findLastKey(flattedRoutes, route => {
      return matchPath(path, route.path);
    });
    return matchRouteKey ? matchRouteKey : null;
  },

  /**
   * 取得模組名稱
   * @param {key} string - 路由 key 值
   * @description 提供各模組取名稱用
   */
  getName(key) {
    if (key) {
      let route = get(routerMatcher, key);
      return route ? route.name : null;
    }
    return null;
  }
};
