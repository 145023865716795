import React from "react";
import Loadable from "react-loadable";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { router } from "../../utils";
import Loading from "../../pages/Loading";
import { secureStroge, storgeKeys } from "../../utils";
import { authActions } from "../../actions";

const Login = Loadable({
  loader: () => import("../../pages/Admin/LoginPages"),
  loading: Loading
});

const Home = Loadable({
  loader: () => import("../../pages/Admin/HomePages"),
  loading: Loading
});

const ConstructionVisit = Loadable({
  loader: () => import("../../pages/Admin/ConstructionVisitPages"),
  loading: Loading
});

const ConstructionVisitSearch = Loadable({
  loader: () => import("../../pages/Admin/ConstructionVisitSearchPages"),
  loading: Loading
});

const ConstructionVisitFeedback = Loadable({
  loader: () => import("../../pages/Admin/ConstructionVisitFeedbackPages"),
  loading: Loading
});

const NotFound = Loadable({
  loader: () => import("../../pages/NotFound"),
  loading: Loading
});

const ProtecedRoute = ({ component: Component, ...rest }) => {
  const { userInfo, selectedConstruction } = useSelector(state => state.auth);
  
  return (
    <Route
      {...rest}
      render={props => {
        const { location } = props;
        if (userInfo && selectedConstruction) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: router.getPath("Admin.Login"),
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};

const ContentRouter = () => {
  return (
    <Switch>
      <Route
        path={router.getPath("Admin.ConstructionVisitFeedback")}
        component={ConstructionVisitFeedback}
        exact
      />
      <Route path={router.getPath("Admin.Login")} component={Login} exact strict />
      <ProtecedRoute
        exact
        path={router.getPath("Admin.ConstructionVisit")}
        component={ConstructionVisit}
      />
      <ProtecedRoute
        exact
        path={router.getPath("Admin.ConstructionVisitSearch")}
        component={ConstructionVisitSearch}
      />
      <ProtecedRoute path={router.getPath("Admin.Home")} component={Home} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default ContentRouter;
