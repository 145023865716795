import { constructionReservationActions, constructionVisitRecordsActions } from "./";

export const constructionVisitCommonActions = {
  /**
   * 清空查詢條件
   */
  clearQueryListResult: () => {
    return dispatch => {
      dispatch(constructionReservationActions.resetAll());
      dispatch(constructionVisitRecordsActions.resetAll());
    };
  }
};
