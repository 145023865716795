import React, { useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import appDownloadQRCodeUrl from "../assets/hyl-app-download-link-qrcode.svg";
import facebookIconUrl from "../assets/facebook-icon.png";
import instagramIconUrl from "../assets/instagram-icon.png";
import lineIconUrl from "../assets/line-icon.png";
import clsx from "clsx";

// 社群媒體資料
const socialMedia = [
  { iconUrl: facebookIconUrl, href: "https://www.facebook.com/khhyl/", label: "facebook" },
  {
    iconUrl: instagramIconUrl,
    href: "https://www.instagram.com/building.hyl/",
    label: "instagram"
  },
  { iconUrl: lineIconUrl, href: "https://line.me/R/ti/p/@dks5025f", label: "line" }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    flex: "0 0 auto",
    color: "white",
    backgroundColor: theme.hyl.primaryColor
  },
  container: {
    padding: theme.spacing(3, 6),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 4)
    }
  },
  content: {
    marginTop: `-${theme.spacing(3)}px`,
    marginBottom: theme.spacing(3)
  },
  infoWrapper: {
    textAlign: "left",
    fontSize: "16px",
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    "& .border-wrapper": {
      display: "inline-block",
      position: "relative",
      padding: "0 0 3px 0",
      marginBottom: "6px",
      background: "linear-gradient(to right, #ccb196, #a7835c)",
      "& h3": {
        display: "inline-block",
        backgroundColor: theme.hyl.primaryColor,
        margin: "0",
        textAlign: "center"
      }
    }
  },
  infoWrapperMargin: {},
  // 聯絡資訊區塊
  contactSection: {
    "& .info": {
      fontSize: "14px",
      "& .info-label": {
        marginRight: "5px",
        "&::after": {
          content: "'：'",
          display: "inline-block"
        }
      }
    }
  },
  // 聯絡客服區塊
  serviceSection: {
    "& .info": {
      fontSize: "14px"
    }
  },
  // 社群媒體區塊
  socialMediaSection: {
    "& img": {
      width: "24px",
      marginLeft: "5px"
    }
  },
  // 華友聯 APP 區塊
  hylFamilyAppSection: {
    "& .wrapper": {
      display: "inline-block",
      [theme.breakpoints.down("lg")]: {
        marginTop: "10px"
      },
      "& .qrcode-wrapper": {
        textAlign: "center",
        position: "relative",
        "& .title": {
          width: "75%",
          left: "50%",
          display: "inline-block",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          color: "white",
          backgroundColor: theme.hyl.primaryColor,
          fontSize: "10px"
        },
        "& img": {
          width: "110px",
          border: "2px solid #B98E61",
          padding: "10px 6px 6px 6px"
        }
      }
    }
  },
  dividerRoot: {
    backgroundColor: "rgba(255,255,255,0.15)",
    margin: "8px 0px"
  },
  copyRightRoot: {
    textAlign: "center"
  }
}));

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSmallSize = useMediaQuery(theme.breakpoints.down("sm"));

  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth={theme.hyl.maxConetnWidthBreakpoint} className={classes.container}>
        <Grid
          container
          justify="space-around"
          alignItems="flex-start"
          spacing={1}
          className={classes.content}
        >
          <Grid
            item
            xs={12}
            sm={5}
            className={clsx(classes.infoWrapper, classes.contactSection)}
          >
            <div className="border-wrapper">
              <h3>高雄總部</h3>
            </div>
            <div className="info">
              <div>
                <label className="info-label">地址</label>
                <span className="info-value">830 高雄市鳳山區文龍路 85 號</span>
              </div>
              <div>
                <label className="info-label">電話</label>
                <span className="info-value">07-7801381</span>
              </div>
              <div>
                <label className="info-label">傳真</label>
                <span className="info-value">07-7777398</span>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            className={clsx(classes.infoWrapper, classes.serviceSection)}
          >
            <div>
              <div className="border-wrapper">
                <h3>聯絡客服</h3>
              </div>
              <div className="info">
                <span>週一 至 週五</span>
                <Divider orientation="vertical" />
                <div>上午 08:30 ~ 下午 17:30</div>
                <Box display="inline-flex" justifyItems="center" alignItems="center">
                  <div>
                    <span>0800-268-588</span>
                    <span>、</span>
                    <span>0800-775-988</span>
                  </div>
                </Box>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            className={clsx(classes.infoWrapper, classes.hylFamilyAppSection)}
          >
            <div className="wrapper">
              <div className="qrcode-wrapper">
                <Link href={process.env.REACT_APP_DOWNLOAD_URL} target="_blank">
                  <span className="title">
                    {matchesSmallSize ? "點擊下載 APP" : "掃描下載 APP"}
                  </span>
                  <img src={appDownloadQRCodeUrl} alt="app-download-qrcode" />
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
        <Divider classes={{ root: classes.dividerRoot }} />
        <Grid container className={classes.copyRightRoot}>
          <Grid item xs={12}>
            Copyright © 2020 - {currentYear} HuaYuLien All rights reserved.
          </Grid>
          <Grid item xs={12} className={classes.socialMediaSection}>
            {socialMedia.map(media => (
              <Link key={media.label} href={media.href} color="inherit" target="_blank">
                <img src={media.iconUrl} alt={`social-media-${media.label}`} />
              </Link>
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
