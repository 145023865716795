import { ajax } from "../utils";

const getCreateVisitRecordEroor = error => {
  const { status, serverMessage } = error;
  if (status === 409) {
    return Promise.reject({ isDuplicate: true, message: serverMessage });
  }

  return null;
};

export const constructionReservationService = {
  /**
   * 取得賞屋預約資料
   *
   * @param {string} constructionUUID - 建案 UUID
   * @param {object} queryConditions - 查詢條件
   */
  getReservations: async (constructionUUID, queryConditions) => {
    try {
      const { data } = await ajax.get(`/official/constructions/${constructionUUID}/reservations`, {
        params: {
          ...queryConditions
        },
        isWithToken: true
      });
      return data;
    } catch (error) {
      return Promise.reject({ message: error.serverMessage });
    }
  },
  /**
   * 指派預約單接待人員
   *
   * @param {string} constructionUUID - 建案 UUID
   * @param {string} reservationIDs - 預約單 IDs
   * @param {array} salesPersonUUIDs - 銷售人員 UUIDs
   */
  assignReceptionist: async (constructionUUID, reservationIDs, salesPersonUUIDs) => {
    try {
      const { message } = await ajax.put(
        `/official/construction-reservations/${constructionUUID}/receptionist/assign`,
        {
          ids: reservationIDs,
          uuids: salesPersonUUIDs
        },
        {
          isWithToken: true
        }
      );
      return message;
    } catch (error) {
      return getCreateVisitRecordEroor(error) || Promise.reject({ message: error.serverMessage });
    }
  }
};
