import React from "react";
import Loadable from "react-loadable";
import { Switch, Route } from "react-router-dom";
import { router } from "../utils";
import Loading from "../pages/Loading";

const Home = Loadable({
  loader: () => import("../pages/HomePages"),
  loading: Loading
});

const ReserveConstruction = Loadable({
  loader: () => import("../pages/ReserveConstructionPages"),
  loading: Loading
});

const PrivacyPolicy = Loadable({
  loader: () => import("../pages/PrivacyPolicyPages"),
  loading: Loading
});

const ConstructionVisitFeedback = Loadable({
  loader: () => import("../pages/Admin/ConstructionVisitFeedbackPages"),
  loading: Loading
});

const ConstructionVisitThanks = Loadable({
  loader: () => import("../pages/Admin/ConstructionVisitThanksPages"),
  loading: Loading
});

const NotFound = Loadable({
  loader: () => import("../pages/NotFound"),
  loading: Loading
});

const ContentRouter = () => {
  return (
    <Switch>
      <Route
        path={router.getPath("PrivacyPolicy")}
        render={routeProps => <PrivacyPolicy {...routeProps} />}
      />
      <Route
        path={router.getPath("ReserveConstruction")}
        render={routeProps => <ReserveConstruction {...routeProps} />}
      />
      <Route
        path={router.getPath("ConstructionVisitFeedback")}
        render={routeProps => <ConstructionVisitFeedback {...routeProps} />}
      />
      <Route
        path={router.getPath("ConstructionVisitThanks")}
        render={routeProps => <ConstructionVisitThanks {...routeProps} />}
      />
      <Route exact path="/" render={routeProps => <ReserveConstruction {...routeProps} />} />
      <Route
        path={router.getPath("NotFound")}
        render={routeProps => <NotFound {...routeProps} />}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default ContentRouter;
