import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: "5px",
    color: "#59a072",
    alignSelf: "center"
  },
  cancelButton: {
    color: "white"
  },
  okButton: {
    color: "white",
    backgroundColor: "#59a072",
    "&:hover": {
      backgroundColor: "#46875d"
    }
  }
}));

const SuccessModal = props => {
  const classes = useStyles();
  const {
    open,
    okText = "確定",
    onOk,
    okButtonProps = {},
    cancelText = "",
    onCancel,
    cancelButtonProps = {},
    title = "通知",
    content,
    onCloseModal,
    ...others
  } = props;

  const { className: okBtnClassName, ...okButtonOtherProps } = okButtonProps;
  const { className: cancelBtnClassName, ...cancelButtonOtherProps } = cancelButtonProps;

  const handleClick = (e, callback) => {
    if (callback && typeof callback === "function") {
      callback(e);
    }

    if (!e.defaultPrevented) {
      onCloseModal();
    }
  };
  
  return (
    <Dialog open={open} fullWidth maxWidth={"xs"} {...others}>
      <DialogTitle>
        <Box display="flex">
          <Box display="flex">
            <CheckCircleOutlineIcon className={classes.icon} />
          </Box>
          <span>{title}</span>
        </Box>
      </DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {cancelText && (
          <Button
            onClick={e => handleClick(e, onCancel)}
            variant="contained"
            fullWidth
            className={clsx(classes.cancelButton, cancelBtnClassName)}
            {...cancelButtonOtherProps}
          >
            {cancelText}
          </Button>
        )}
        <Button
          onClick={e => handleClick(e, onOk)}
          variant="contained"
          fullWidth
          className={clsx(classes.okButton, okBtnClassName)}
          {...okButtonOtherProps}
        >
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessModal;
