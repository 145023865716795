import { ajax } from "../utils";

const getCreateVisitRecordEroor = error => {
  const { status, serverMessage } = error;
  if (status === 409) {
    return Promise.reject({ isDuplicate: true, message: serverMessage });
  }

  return null;
};

export const constructionVisitService = {
  /**
   * 取得特定建案的賞屋作業單資料 by 客戶手機號碼
   *
   * @param {string} constructionUUID - 建案 UUID
   * @param {string} telephone - 客戶手機號碼
   */
  getVisitRecordInConstructionByTelephone: async (constructionUUID, telephone) => {
    try {
      const { data } = await ajax.get(
        `/official/constructions/${constructionUUID}/visits/${telephone}`,
        {
          isWithToken: true
        }
      );
      return data;
    } catch (error) {
      return Promise.reject({ message: error.message });
    }
  },
  /**
   * 取得賞屋作業單資料 by 賞屋作業單 id
   *
   * @param {string} constructionVisitUUID - 賞屋作業單 UUID
   */
  getVisitRecordById: async constructionVisitUUID => {
    try {
      const { data } = await ajax.get(`/official/construction-visits/${constructionVisitUUID}`, {
        isWithToken: true
      });
      return data;
    } catch (error) {
      return Promise.reject({ message: error.serverMessage });
    }
  },
  /**
   * 新增賞屋作業單
   *
   * @param {string} visitRecordUUID - 建案 UUID
   * @param {object} profileInfo - 作業單基本資料區塊資訊
   */
  insertConstructionVisitProfile: async (constructionUUID, profileInfo) => {
    try {
      const { data } = await ajax.post(
        `/official/constructions/${constructionUUID}/visit-profile-survey`,
        profileInfo,
        {
          isWithToken: true
        }
      );

      return data;
    } catch (error) {
      return getCreateVisitRecordEroor(error) || Promise.reject({ message: error.message });
    }
  },
  /**
   * 修改賞屋作業單
   *
   * @param {string} visitRecordUUID - 賞屋作業單 UUID
   * @param {object} profileInfo - 作業單基本資料區塊資訊
   */
  updateConstructionVisitProfile: async (constructionVisitUUID, profileInfo) => {
    try {
      const { data } = await ajax.put(
        `/official/construction-visits/${constructionVisitUUID}/profile-survey`,
        profileInfo,
        {
          isWithToken: true
        }
      );
      return data;
    } catch (error) {
      return getCreateVisitRecordEroor(error) || Promise.reject({ message: error.message });
    }
  },
  /**
   * 取得賞屋作業單顧客問卷區塊資料
   *
   * @param {string} visitRecordUUID - 賞屋作業單 UUID
   */
  getConstructionVisitFeedback: async constructionVisitUUID => {
    try {
      const { data } = await ajax.get(
        `/official/construction-visits/${constructionVisitUUID}/feedback-survey`
      );
      return {
        feedback: { constructionVisitUUID, ...data },
        isFinished: false,
        isNotFound: false
      };
    } catch (error) {
      const { status } = error;
      if (status === 400) {
        return {
          feedback: {},
          isFinished: false,
          isNotFound: true
        };
      }

      if (status === 406) {
        return {
          feedback: {},
          isFinished: true,
          isNotFound: false
        };
      }

      return Promise.reject({ message: error.message });
    }
  },
  /**
   * 新增賞屋作業單顧客回饋區塊資料
   *
   * @param {string} visitRecordUUID - 賞屋作業單 UUID
   * @param {object} feedbackInfo - 作業單顧客回饋區塊資訊
   */
  insertConstructionVisitFeedback: async (constructionVisitUUID, feedbackInfo) => {
    try {
      const { data } = await ajax.put(
        `/official/construction-visits/${constructionVisitUUID}/feedback-survey`,
        feedbackInfo
      );

      return data;
    } catch (error) {
      const { status, serverMessage } = error;
      if (status === 406) {
        return Promise.reject({ isFinished: true, message: serverMessage });
      }

      return Promise.reject({ message: error.message });
    }
  },
  /**
   * 更新賞屋作業單追蹤處理區塊資料
   *
   * @param {string} constructionVisitUUID - 賞屋作業單 UUID
   * @param {object} trackInfo - 作業單顧客回饋區塊資訊
   */
  updateConstructionVisitTrack: async (constructionVisitUUID, trackInfo) => {
    try {
      const { message } = await ajax.put(
        `/official/construction-visits/${constructionVisitUUID}/track-survey`,
        trackInfo,
        {
          isWithToken: true
        }
      );

      return message;
    } catch (error) {
      return Promise.reject({ message: error.serverMessage });
    }
  },
  /**
   * 取得賞屋作業單留言
   *
   * @param {string} constructionVisitUUID - 賞屋作業單 UUID
   */
  getConstructionVisitMessages: async constructionVisitUUID => {
    try {
      const { data } = await ajax.get(
        `/official/construction-visits/${constructionVisitUUID}/messages`,
        {
          isWithToken: true
        }
      );

      return data;
    } catch (error) {
      return Promise.reject({ message: error.message });
    }
  },
  /**
   * 新增賞屋作業單留言
   *
   * @param {string} constructionVisitUUID - 賞屋作業單 UUID
   * @param {string} message - 留言
   */
  insertConstructionVisitMessage: async (constructionVisitUUID, message) => {
    try {
      const { data, message: responseMessage } = await ajax.post(
        `/official/construction-visits/${constructionVisitUUID}/messages`,
        message,
        {
          isWithToken: true
        }
      );

      return {
        messageItems: data,
        message: responseMessage
      };
    } catch (error) {
      return Promise.reject({ message: error.serverMessage });
    }
  },
  /**
   * 取得賞屋作業單紀錄列表
   *
   * @param {string} constructionUUID - 建案 UUID
   * @param {object} queryConditions - 查詢條件
   */
  getVisitRecords: async (constructionUUID, queryConditions) => {
    try {
      const { data } = await ajax.get(`/official/constructions/${constructionUUID}/visits`, {
        params: {
          ...queryConditions
        },
        isWithToken: true
      });
      return data;
    } catch (error) {
      return Promise.reject({ message: error.message });
    }
  },
  /**
   * 指派銷售人員
   *
   * @param {string} constructionUUID - 建案 UUID
   * @param {array} constructionVisitIDs - 要指派的作業單 IDs
   * @param {array} salesPersonsUUIDs - 要指派的銷售人員 UUIDs
   */
  assignVisitReceptionist: async (constructionUUID, constructionVisitIDs, salesPersonUUIDs) => {
    try {
      const data = await ajax.put(
        `/official/constructions/${constructionUUID}/receptionist/assign`,
        {
          ids: constructionVisitIDs,
          uuids: salesPersonUUIDs
        },
        {
          isWithToken: true
        }
      );

      return data;
    } catch (error) {
      return Promise.reject({ message: error.message });
    }
  },
  /**
   * 移除指派銷售人員
   *
   * @param {string} constructionUUID - 建案 UUID
   * @param {array} constructionVisitIDs - 要移除指派的作業單 IDs
   * @param {array} salesPersonUUIDs - 要移除指派的銷售人員 UUIDs
   */
  removeAssignedVisitReceptionist: async (
    constructionUUID,
    constructionVisitIDs,
    salesPersonUUIDs
  ) => {
    try {
      const data = await ajax.put(
        `/official/constructions/${constructionUUID}/receptionist/remove`,
        { ids: constructionVisitIDs, uuids: salesPersonUUIDs },
        {
          isWithToken: true
        }
      );

      return data;
    } catch (error) {
      return Promise.reject({ message: error.message });
    }
  },
  /**
   * 設定忽略
   *
   * @param {string} constructionUUID - 建案 UUID
   * @param {array} params - 忽略或非忽略參數
   */
  ingoreVisitRecords: async (constructionUUID, params) => {
    try {
      const data = await ajax.put(
        `/official/constructions/${constructionUUID}/visits/visible`,
        {
          ...params
        },
        {
          isWithToken: true
        }
      );

      return data;
    } catch (error) {
      return Promise.reject({ message: error.message });
    }
  },
  /**
   * 設定約定接待時間
   *
   * @param {string} constructionUUID - 建案 uuid
   * @param {string}} telephone - 電話
   * @param {array} params - 約定時間相關資料
   */
  settingAppointment: async (constructionUUID, telephone, params) => {
    try {
      const data = await ajax.put(
        `/official/constructions/${constructionUUID}/reservations/${telephone}/confirm-reserve`,
        {
          ...params
        },
        {
          isWithToken: true
        }
      );

      return data;
    } catch (error) {
      return Promise.reject({ message: error.serverMessage });
    }
  },
  /**
   * 取消約定接待時間
   *
   * @param {number} reservationId - 賞屋預約單 id
   * @param {array} params - 取消時間相關資料
   */
  cancelAppointment: async (reservationId, params) => {
    try {
      const data = await ajax.put(
        `/official/constructions-reservations/${reservationId}/cancel-reserve`,
        {
          ...params
        },
        {
          isWithToken: true
        }
      );

      return data;
    } catch (error) {
      return Promise.reject({ message: error.serverMessage });
    }
  }
};
