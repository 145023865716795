export const authConstants = {
  // 登入相關
  LOGIN_REQUEST: "AUTH_LOGIN_REQUEST",
  LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
  REFRESH_LOGIN_SUCCESS: "AUTH_REFRESH_LOGIN_SUCCESS",
  LOGING_FAILURE: "AUTH_LOGIN_FAILURE",
  LOGOUT_SUCCESS: "AUTH_LOGOUT_SUCCESS",
  CLEAR_LOGIN_FAILURE: "AUTH_CLEAR_LOGIN_FAILURE",
  // 要處理之建案相關
  SELECT_CONSTRUCTION: "AUTH_SELECTE_CONSTRUCTION",
};
