import SecureLS from 'secure-ls'

/**
 * Storage Keys.
 */
export const storgeKeys = {
    // 使用者記住帳密
    userRemember: 'hylOfficialAdmin.userRemember',
    // 使用者登入憑證
    userCredential: 'hylOfficialAdmin.userCredential',
    // 使用者資料
    userAuth: 'hylOfficialAdmin.userAuth',
    // 使用者所選取的建案
    userSelectedConstruction: 'hylOfficialAdmin.selectedConstruction',
};

/**
 * Secure LocalStorage.
 * @description https://varunmalhotra.xyz/secure-ls/
 */
export const secureStroge = new SecureLS({ encodingType: 'des', isCompression: false, encryptionSecret: 'i-love-hyl-official' });