export const constructionConstants = {
  // 建案清單相關
  GET_LIST_REQUEST: "CONSTRUCTION_GET_LIST_REQUEST",
  GET_LIST_SUCCESS: "CONSTRUCTION_GET_LIST_SUCCESS",
  GET_LIST_FAILURE: "CONSTRUCTION_GET_LIST_FAILURE",
  RESET_LIST_SUCCESS: "CONSTRUCTION_RESET_LIST_SUCCESS",
  // 選取建案相關
  SELECT_ITEM_BY_ID: "CONSTRUCTION_SELECT_ITEM_BY_ID",
  SELECT_ITEM_BY_UUID: "CONSTRUCTION_SELECT_ITEM_BY_UUID",
  CLEAR_ITEM: "CONSTRUCTION_CLEAR_ITEM",
  // 取得建案銷售人員清單相關
  GET_SALES_PERSON_LIST_REQUEST: "CONSTRUCTION_GET_SALES_PERSON_LIST_REQUEST",
  GET_SALES_PERSON_LIST_SUCCESS: "CONSTRUCTION_GET_SALES_PERSON_LIST_SUCCESS",
  GET_SALES_PERSON_LIST_FAILURE: "CONSTRUCTION_GET_SALES_PERSON_LIST_FAILURE",
  RESET_SALES_PERSON_LIST: "CONSTRUCTION_RESET_SALES_PERSON_LIST",
  // 取得建案銷售人員清單相關
  GET_SALES_PERSON_LIST_FOR_QUERY_FORM_REQUEST:
    "CONSTRUCTION_GET_SALES_PERSON_LIST_FOR_QUERY_FORM_REQUEST",
  GET_SALES_PERSON_LIST_FOR_QUERY_FORM_SUCCESS:
    "CONSTRUCTION_GET_SALES_PERSON_LIST_FOR_QUERY_FORM_SUCCESS",
  GET_SALES_PERSON_LIST_FOR_QUERY_FORM_FAILURE:
    "CONSTRUCTION_GET_SALES_PERSON_LIST_FOR_QUERY_FORM_FAILURE",
  RESET_SALES_PERSON_LIST_FOR_QUERY_FORM: "CONSTRUCTION_RESET_SALES_PERSON_LIST_FOR_QUERY_FORM"
};
