import { createMuiTheme } from "@material-ui/core/styles";

const hylTheme = {
  primaryColor: "#133146",
  // 網頁內容最大寬度（ref: https://material-ui.com/customization/breakpoints/#default-breakpoints）
  maxContentWidthBreakpoint: "lg"
};

const theme = createMuiTheme({
  // 可以自訂 style 相關的變數，供各子元件統一使用
  hyl: {
    ...hylTheme
  },
  // 可複寫 Material UI 元件的樣式
  palette: {
    primary: {
      main: hylTheme.primaryColor
    }
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  // 可複寫 Material UI 元件的屬性預設值
  props: {
    MuiFormControl: {
      variant: "outlined",
      fullWidth: true
    },
    MuiTextField: {
      variant: "outlined",
      fullWidth: true
    },
    MuiLink: {
      underline: "none"
    }
  },
  overrides: {
    // 可加入全域樣式
    MuiCssBaseline: {
      "@global": {
      }
    }
  }
});

export default theme;
