import { ajax } from "../utils";

export const constructionService = {
  /**
   * 取得建案清單資料
   *
   */
  getList: async () => {
    try {
      const { data } = await ajax.get(`public-constructions`);
      return data;
    } catch (error) {
      return Promise.reject({ message: error.message });
    }
  },
  /**
   * 預約建案
   * @param id - 建案 id
   * @param info - 預約資訊
   */
  reserve: async (id, info) => {
    try {
      const response = await ajax.post(`/public-construction/${id}/reserve`, info);
      const { status } = response;
      return {
        id,
        isDuplicateReserve: status === 205
      };
    } catch (error) {
      return Promise.reject({ message: error.message });
    }
  },
  /**
   * 取得預約建案來源清單
   */
  getSourceCategoryList: async () => {
    try {
      const { data } = await ajax.get(`/public-construction-source-categories/list`);

      return data;
    } catch (error) {
      return Promise.reject({ message: error.message });
    }
  },
  /**
   * 取得建案賞屋作業客戶問卷資料區塊題目
   *
   * @param {string} constructionUUID - 建案 UUID
   */
  getVisitProfileSurvey: async constructionUUID => {
    try {
      const { data } = await ajax.get(
        `/official/constructions/${constructionUUID}/visit-profile-survey`,
        {
          isWithToken: true
        }
      );
      return data;
    } catch (error) {
      return Promise.reject({ message: error.serverMessage });
    }
  },
  /**
   * 取得建案銷售人員清單
   *
   * @param {string} constructionUUID - 建案 UUID
   * @param {array} constructionVisitUUIDs - 賞屋作業單 UUIDs
   * @param {boolean} isOnJob - 是否銷售人員在職中
   */
  getConstructionSalesPersonList: async (constructionUUID, constructionVisitUUIDs, isOnJob) => {
    try {
      const { data } = await ajax.post(
        `/official/constructions/${constructionUUID}/salesperson/list`,
        {
          uuids: constructionVisitUUIDs ? [...constructionVisitUUIDs] : undefined,
          is_on_job: isOnJob
        },
        {
          isWithToken: true
        }
      );
      return data;
    } catch (error) {
      return Promise.reject({ message: error.serverMessage });
    }
  }
};
