import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Header from "./Header";
import Content from "./Content";
import { ThemeProvider } from "@material-ui/core/styles";
import { adminTheme } from "../../themes";
import { useDispatch, useSelector } from "react-redux";
import { authActions, alertActions } from "../../actions";
import ModalContainer from "../../components/Modal/ModalContainer";

const CommonSnackBar = ({ open, severity, message, onClose }) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={2000}
      onClose={onClose}
    >
      <Alert elevation={6} variant="filled" severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const CommonSnackBarContainer = () => {
  const alert = useSelector(state => state.alert);
  const disptach = useDispatch();

  const onClose = () => {
    disptach(alertActions.hide());
  };
  return (
    <CommonSnackBar
      open={alert.open}
      severity={alert.alertType}
      message={alert.message}
      onClose={onClose}
    />
  );
};

const AdminLayout = withStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflowX: "hidden"
  }
}))(({ classes }) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(authActions.refresh());
  }, [dispatch]);

  return (
    <ThemeProvider theme={adminTheme}>
      <CommonSnackBarContainer />
      <div className={classes.root}>
        <Header />
        <Content />
        <ModalContainer />
      </div>
    </ThemeProvider>
  );
});

export default AdminLayout;
