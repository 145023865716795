import { constructionReservationContstans } from "../constants";
import { constructionReservationService } from "../services";
import { alertActions } from "../actions";

export const constructionReservationActions = {
  /**
   * 取得賞屋預約資料列表
   *
   * @param {object} conditions - 查詢條件
   */
  getAll: conditions => {
    return async (dispatch, getState) => {
      try {
        const {
          auth: { selectedConstruction }
        } = getState();

        dispatch({
          type: constructionReservationContstans.GET_ALL_REQUEST
        });

        const {
          total,
          items,
          unassigned_total
        } = await constructionReservationService.getReservations(selectedConstruction, conditions);

        dispatch({
          type: constructionReservationContstans.GET_ALL_SUCCESS,
          total,
          items,
          unassigned_total,
          conditions
        });
      } catch (error) {
        dispatch({
          type: constructionReservationContstans.GET_ALL_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   * 重設查詢結果資料（不包含查詢條件）
   */
  resetItems: () => {
    return {
      type: constructionReservationContstans.RESET_ITEMS
    };
  },
  /**
   * 重置取得賞屋預約資料列表
   */
  resetAll: () => {
    return {
      type: constructionReservationContstans.RESET_GET_ALL
    };
  },
  /**
   * 指派接待人員
   *
   * @param {array} reservationIDs - 預約單 IDs
   * @param {array} salesPersonUUIDs - 銷售人員 UUIDs
   * @param {function} successCb - 成功執行 callback
   */
  assignReceptionist: (reservationIDs, salesPersonUUIDs, successCb) => {
    return async (dispatch, getState) => {
      try {
        const {
          auth: { selectedConstruction }
        } = getState();

        dispatch({
          type: constructionReservationContstans.ASSIGN_RECEPTIONIST_REQUEST
        });

        const message = await constructionReservationService.assignReceptionist(
          selectedConstruction,
          reservationIDs,
          salesPersonUUIDs
        );
        dispatch(alertActions.show(message));

        successCb && successCb();

        // 重新查詢資料
        const {
          reservations: { conditions }
        } = getState().constructionReservations;
        dispatch(constructionReservationActions.getAll(conditions));

        dispatch({
          type: constructionReservationContstans.ASSIGN_RECEPTIONIST_SUCCESS
        });
      } catch (error) {
        dispatch({
          type: constructionReservationContstans.ASSIGN_RECEPTIONIST_FAILURE,
          error: error.message
        });
        dispatch(alertActions.show(error.message, "error"));
      }
    };
  }
};
