import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { maskActions } from "../../actions";
import LinearProgress from "@material-ui/core/LinearProgress";

// TODO 尚未補上全域 Loading 圖示顯示功能
const Loading = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(maskActions.toggleMask(true));
    return () => {
      // dispatch(maskActions.toggleMask(false));
    };
  }, [dispatch]);

  // return <div>loading ...</div>;
  return <LinearProgress color="secondary" />;
};

export default Loading;
