import { constructionVisitSurveyConstants } from "../constants";
import { createSelector } from "reselect";

const defaultEmptyObj = {};

export const selectSurveyFactory = surveyType => {
  return createSelector(
    state => state.constructionVisitSurvey[surveyType],
    survey => survey || defaultEmptyObj
  );
};

export const selectSurveyIdentity = createSelector(
  state => state.constructionVisitSurvey.surveyIdentity,
  surveyIdentity => surveyIdentity
);

const initState = {
  surveyIdentity: {
    reservation_id: null,
    uuid: null,
    receipt_number: null,
    receptionists: []
  }
};

export const constructionVisitSurvey = (state = initState, action) => {
  switch (action.type) {
    case constructionVisitSurveyConstants.SET_SURVEY:
      return {
        ...state,
        [action.surveyType]: {
          ...action.survey
        }
      };
    case constructionVisitSurveyConstants.SET_SURVEY_IDENTITY:
      return {
        ...state,
        surveyIdentity: {
          ...action.surveyIdentity
        }
      };
    case constructionVisitSurveyConstants.RESET_SURVEY_IDENTITY:
      // 快取，強制更新時回傳新的 ref，使相關元件可以 rerender
      if (action.forceCreateNewStatus) {
        return {
          ...state,
          surveyIdentity: {
            ...initState.surveyIdentity
          }
        };
      } else {
        // 當 surveyIdentity 為初始空狀態時，回傳原 state，避免相關元件 rerender
        if (state.surveyIdentity === initState.surveyIdentity) {
          return state;
        } else {
          return {
            ...state,
            surveyIdentity: initState.surveyIdentity
          };
        }
      }
    case constructionVisitSurveyConstants.RESET_ALL:
      return {
        ...initState
      };
    default:
      return state;
  }
};
