import React, { useState, useMemo } from "react";
import { Link as RouterLink, useLocation, matchPath } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import BusinessIcon from "@material-ui/icons/Business";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import GetAppIcon from "@material-ui/icons/GetApp";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import Chip from "@material-ui/core/Chip";
import { router, statusMap } from "../utils";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";

// 建案佳績 menu
const ConstructionMenu = withStyles(theme => ({
  menuItem: {},
  menuItemGroupTitle: {},
  menuSelectedItem: {
    backgroundColor: "#dff2ff !important", // 沒有 important 會蓋不掉原本的樣式
    "& .name": {
      fontWeight: "800"
    }
  },
  menuItemText: {
    "& .city": {
      color: "#c3c3c3",
      fontSize: "14px",
      marginRight: "8px"
    }
  },
  tagContainer: {
    width: "80px"
  },
  tag: {
    color: "white"
  },
  tagLabel: {
    fontSize: "8px"
  },
  tagRoot: {
    height: "20px",
    marginLeft: "8px",
    borderRadius: "6px",
    padding: "2px"
  }
}))(
  ({
    classes,
    constructions,
    constructionStatusColorMap,
    onSelectConstruction,
    onCloseMenu,
    openCollapse,
    onOpenCollapse
  }) => {
    const location = useLocation();
    const match = useMemo(
      () => matchPath(location.pathname, router.getPath("ReserveConstruction")),
      [location]
    );

    const renderGroupList = constructions => {
      if (constructions?.length > 0) {
        return constructions.map(construction => {
          return (
            <Link
              key={construction.id}
              component={RouterLink}
              to={router.getPath("ReserveConstruction", {
                constructionUUID: construction.uuid
              })}
              onClick={() => {
                onSelectConstruction(construction.id);
                onCloseMenu();
              }}
            >
              <List>
                <MenuItem
                  button
                  className={classes.menuItem}
                  selected={match && match.params.constructionUUID === construction.uuid}
                  classes={{ selected: classes.menuSelectedItem }}
                >
                  <ListItemText className={classes.menuItemText}>
                    <span className="city">{construction.city}</span>
                    <span className="name">{construction.name}</span>
                  </ListItemText>
                  <div className={classes.tagContainer}>
                    <Chip
                      label={statusMap.constructionStatus[construction.status]?.label || ""}
                      size="small"
                      className={classes.tag}
                      classes={{ root: classes.tagRoot, label: classes.tagLabel }}
                      style={{
                        backgroundColor: constructionStatusColorMap.get(construction.status)
                          ?.bgColor,
                        color: statusMap.constructionStatus[construction.status]?.color || null,
                        borderColor:
                          statusMap.constructionStatus[construction.status]?.color || null
                      }}
                      variant="outlined"
                    />
                  </div>
                </MenuItem>
              </List>
            </Link>
          );
        });
      }
      return null;
    };

    return (
      constructions && (
        <>
          <ListItem button onClick={onOpenCollapse}>
            <ListItemIcon>
              <BusinessIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="建案佳績" />
            {openCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Divider />
          {constructions.length > 0 && (
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              {renderGroupList(
                constructions.filter(construction => construction.status === "coming_soon")
              )}
              {renderGroupList(
                constructions.filter(construction => construction.status === "selling")
              )}
              {renderGroupList(
                constructions.filter(construction => construction.status === "sellout")
              )}
            </Collapse>
          )}
        </>
      )
    );
  }
);

// 相關企業 menu
const RelatedCompaniesMenu = withStyles(theme => ({
  menuItem: {}
}))(({ classes, relatedCompanies, onCloseMenu, openCollapse, onOpenCollapse }) => {
  return (
    relatedCompanies && (
      <>
        <ListItem button onClick={onOpenCollapse}>
          <ListItemIcon>
            <DynamicFeedIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="相關企業" />
          {openCollapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        {relatedCompanies.length > 0 && (
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            {relatedCompanies.map(relatedCompany => (
              <Link
                key={relatedCompany.title}
                href={relatedCompany.url}
                target="_blank"
                onClick={onCloseMenu}
              >
                <List>
                  <MenuItem button className={classes.menuItem}>
                    <ListItemText>{relatedCompany.title}</ListItemText>
                  </MenuItem>
                </List>
              </Link>
            ))}
          </Collapse>
        )}
      </>
    )
  );
});

// 下載 APP menu
const DownloadAppMenu = ({ downloadAppUrl }) => {
  return (
    downloadAppUrl && (
      <>
        <Link href={downloadAppUrl} target="_blank">
          <ListItem button>
            <ListItemIcon>
              <GetAppIcon color="primary" />
            </ListItemIcon>
            <ListItemText>下載 APP</ListItemText>
          </ListItem>
        </Link>
        <Divider />
      </>
    )
  );
};

// 隱私權政策 menu
const PrivacyPolicy = ({ onCloseMenu }) => {
  return (
    <>
      <Link component={RouterLink} to={router.getPath("PrivacyPolicy")} onClick={onCloseMenu}>
        <ListItem button>
          <ListItemIcon>
            <MenuBookIcon color="primary" />
          </ListItemIcon>
          <ListItemText>隱私權政策</ListItemText>
        </ListItem>
      </Link>
      <Divider />
    </>
  );
};

// 前往後台 menu
const AdminHomePage = ({ onCloseMenu }) => {
  return (
    <Link component={RouterLink} to={router.getPath("Admin.Home")} onClick={onCloseMenu}>
      <ListItem button>
        <ListItemIcon>
          <LaunchOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText>進入後台</ListItemText>
      </ListItem>
    </Link>
  );
};

// 向上滑出動畫效果
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// 小裝置 menus
const HeaderMobileMenus = withStyles(theme => ({
  paperRoot: {
    width: "70vw"
  },
  menuItem: {
    paddingLeft: theme.spacing(4)
  },
  menuItemGroupTitle: {
    paddingLeft: theme.spacing(2)
  },
  smallSizeDialogPaperRoot: {
    boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
  }
}))(
  ({
    classes,
    constructions,
    constructionStatusColorMap,
    onSelectConstruction,
    relatedCompanies,
    downloadAppUrl
  }) => {
    const matched = useMediaQuery("(max-width:400px)");
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [currentOpenCollapse, setCurrentOpenCollapse] = useState(null);
    const handleOpenMenu = () => setIsOpenMenu(true);
    const handleCloseMenu = () => setIsOpenMenu(false);
    const handleOpenCollapse = collapseId => {
      setCurrentOpenCollapse(collapseId === currentOpenCollapse ? null : collapseId);
    };

    const renderMenuListContent = () => {
      return (
        <List>
          <ConstructionMenu
            classes={{ menuItem: classes.menuItem, menuItemGroupTitle: classes.menuItemGroupTitle }}
            constructions={constructions}
            constructionStatusColorMap={constructionStatusColorMap}
            onSelectConstruction={onSelectConstruction}
            onCloseMenu={handleCloseMenu}
            openCollapse={currentOpenCollapse === "constructionMenu"}
            onOpenCollapse={() => handleOpenCollapse("constructionMenu")}
          />
          <RelatedCompaniesMenu
            classes={{ menuItem: classes.menuItem }}
            relatedCompanies={relatedCompanies}
            onCloseMenu={handleCloseMenu}
            openCollapse={currentOpenCollapse === "relatedCompaniesMenu"}
            onOpenCollapse={() => handleOpenCollapse("relatedCompaniesMenu")}
          />
          <DownloadAppMenu downloadAppUrl={downloadAppUrl} />
          <PrivacyPolicy onCloseMenu={handleCloseMenu} />
          <AdminHomePage onCloseMenu={handleCloseMenu} />
        </List>
      );
    };

    return (
      <>
        <IconButton color="inherit" onClick={handleOpenMenu}>
          <MenuIcon />
        </IconButton>
        {matched ? (
          <Dialog
            fullScreen
            open={isOpenMenu}
            onClose={handleCloseMenu}
            keepMounted
            TransitionComponent={Transition}
          >
            <AppBar
              position="fixed"
              color="inherit"
              classes={{ root: classes.smallSizeDialogPaperRoot }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseMenu}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Toolbar />
            {renderMenuListContent(true)}
          </Dialog>
        ) : (
          <Drawer
            // 避免被 unmount，導致關閉前的狀態不見
            ModalProps={{ keepMounted: true }}
            anchor="right"
            open={isOpenMenu}
            onClose={handleCloseMenu}
            PaperProps={{
              classes: { root: classes.paperRoot }
            }}
          >
            {renderMenuListContent(false)}
          </Drawer>
        )}
      </>
    );
  }
);

export default HeaderMobileMenus;
