import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import logoUrl from "../assets/check/logo.png";
import { constructionActions } from "../actions";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    cursor: "pointer"
  },
  logo: {
    width: "120px",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      width: "180px"
    }
  }
}));

const HeaderLogo = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleOnClick = () => {
    dispatch(constructionActions.clearItem());
    history.push("/");
  };

  return (
    <div className={classes.root} onClick={handleOnClick}>
      <img src={logoUrl} className={classes.logo} alt="logo" />
    </div>
  );
};

export default HeaderLogo;
