import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";
import LoadableButton from "../LoadableButton";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: "5px",
    color: "#B98E61",
    alignSelf: "center"
  },
  okButton: {
    color: "white",
    backgroundColor: "#B98E61",
    "&:hover": {
      backgroundColor: "#A67B4E"
    }
  },
  paperRoot: {
    padding: theme.spacing(0, 2, 1, 2)
  }
}));

const ConfirmModal = props => {
  const classes = useStyles();
  const {
    open,
    okText = "確定",
    onOk,
    okButtonProps = {},
    title = "警告",
    content,
    onCloseModal,
    cancelText = "取消",
    onCancel,
    cancelButtonProps = {},
    ...others
  } = props;

  const { className: okBtnClassName, ...okButtonOtherProps } = okButtonProps;
  const { className: cancelBtnClassName, ...cancelButtonOtherProps } = cancelButtonProps;

  const handleClick = (e, callback) => {
    if (callback && typeof callback === "function") {
      callback(e);
    }

    if (!e.defaultPrevented) {
      onCloseModal();
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"xs"}
      classes={{ paper: classes.paperRoot }}
      {...others}
    >
      <DialogTitle>
        <Box display="flex">
          <Box display="flex">
            <ErrorOutlineIcon className={classes.icon} />
          </Box>
          <span>{title}</span>
        </Box>
      </DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={e => handleClick(e, onCancel)}
          variant="contained"
          fullWidth
          className={clsx(classes.cancelButton, cancelBtnClassName)}
          {...cancelButtonOtherProps}
        >
          {cancelText}
        </Button>
        <LoadableButton
          onClick={e => handleClick(e, onOk)}
          variant="contained"
          fullWidth
          className={clsx(classes.okButton, okBtnClassName)}
          {...okButtonOtherProps}
        >
          {okText}
        </LoadableButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
