import { constructionVisitConstants } from "../constants";
import moment from "moment";

const initState = {
  // 目前所選建案的預設客戶資料題目
  currentProfileSurvey: {
    loading: false,
    error: null
  },
  // 取得作業單資料
  recordById: {
    loading: false,
    error: null
  },
  // 更新作業單追蹤處理資料
  updateVisitTrackSurveyLoading: false,
  // 留言訊息
  visitMessages: {
    loading: false,
    data: null,
    error: null
  },
  // 最新一筆留言訊息
  visitLastMessage: null,
  // 新增留言訊息
  insertVisitMessageLoading: false
};

export const constructionVisit = (state = initState, action) => {
  switch (action.type) {
    // -------- 取得目前所選建案的問卷題目 START --------
    case constructionVisitConstants.GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_REQUEST:
      return {
        ...state,
        currentProfileSurvey: {
          ...initState.currentProfileSurvey,
          loading: true
        }
      };
    case constructionVisitConstants.GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_DONE:
      return {
        ...state,
        currentProfileSurvey: {
          ...initState.currentProfileSurvey,
          loading: false
        }
      };
    case constructionVisitConstants.GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_FAILURE:
      return {
        ...state,
        currentProfileSurvey: {
          ...initState.currentProfileSurvey,
          loading: false,
          error: action.error
        }
      };
    case constructionVisitConstants.RESET_GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY:
      return {
        ...state,
        currentProfileSurvey: initState.currentProfileSurvey
      };
    // -------- 更新作業單追蹤處理資料 START --------
    case constructionVisitConstants.UPDATE_VISIT_TRACK_SURVEY_REQUEST:
      return {
        ...state,
        updateVisitTrackSurveyLoading: true
      };
    case constructionVisitConstants.UPDATE_VISIT_TRACK_SURVEY_DONE:
      return {
        ...state,
        updateVisitTrackSurveyLoading: false
      };
    // -------- 更新作業單追蹤處理資料 END --------

    // -------- 依 id 取得作業單資料 START --------
    case constructionVisitConstants.GET_VISIT_RECORD_BY_ID_REQUEST:
      return {
        ...state,
        recordById: {
          ...initState.recordById,
          loading: true
        }
      };
    case constructionVisitConstants.GET_VISIT_RECORD_BY_ID_DONE:
      return {
        ...state,
        recordById: {
          ...initState.recordById,
          loading: false
        }
      };
    case constructionVisitConstants.GET_VISIT_RECORD_BY_ID_FAILURE:
      return {
        ...state,
        recordById: {
          ...initState.recordById,
          loading: false,
          error: action.error
        }
      };
    case constructionVisitConstants.RESET_VISIT_RECORD_BY_ID:
      return {
        ...state,
        recordById: initState.recordById
      };
    // -------- 依 id 取得作業單資料 END --------

    // -------- 取得作業單留言資料 START --------
    case constructionVisitConstants.GET_VISIT_MESSAGES_REQUEST:
      return {
        ...state,
        visitMessages: {
          ...initState.visitMessages,
          loading: true,
          error: null
        }
      };
    case constructionVisitConstants.GET_VISIT_MESSAGES_SUCCESS: {
      const { messages } = action;
      let lastMessages = null;
      let sortedMessages = null;
      if (messages) {
        let shallowCopiedMessages = [...messages];
        // 依 create 時間排序
        shallowCopiedMessages.sort((m1, m2) => {
          const moment1 = moment(m1.created_at);
          const moment2 = moment(m2.created_at);
          if (moment1 > moment2) return 1;
          else if (moment1 < moment2) return -1;
          else return 0;
        });
        sortedMessages = shallowCopiedMessages;
        // 取新一筆 message
        lastMessages =
          shallowCopiedMessages.length > 0
            ? shallowCopiedMessages[shallowCopiedMessages.length - 1]
            : null;
      }

      return {
        ...state,
        visitMessages: {
          ...initState.visitMessages,
          loading: false,
          data: sortedMessages
        },
        visitLastMessage: lastMessages
      };
    }
    case constructionVisitConstants.GET_VISIT_MESSAGES_FAILURE:
      return {
        ...state,
        visitMessages: {
          ...initState.visitMessages,
          data: state.visitMessages.data,
          error: action.error
        }
      };
    case constructionVisitConstants.RESET_VISIT_MESSAGES:
      return {
        ...state,
        visitMessages: initState.visitMessages
      };
    // -------- 取得作業單留言資料 END --------

    // -------- 新增作業單留言資料 START --------
    case constructionVisitConstants.INSERT_VISIT_MESSAGE_REQUEST:
      return {
        ...state,
        insertVisitMessageLoading: true
      };
    case constructionVisitConstants.INSERT_VISIT_MESSAGE_DONE:
      return {
        ...state,
        insertVisitMessageLoading: false
      };
    // -------- 新增作業單留言資料 END --------

    case constructionVisitConstants.RESET_ALL:
      return initState;
    default:
      return state;
  }
};
