import {
  constructionVisitConstants,
  constructionVisitFeedbackConstants,
  constructionVisitRecordsConstants
} from "../constants";
import { constructionVisitService, constructionService } from "../services";
import {
  maskActions,
  modalActions,
  alertActions,
  constructionVisitSurveyActions,
  CONSTRUCTION_VISIT_SURVEY_TYPE
} from "../actions";

export const constructionVisitActions = {
  /**
   * 取得目前所選建案的問卷題目
   */
  getCurrentSelectedConstructionVisitProfileSurvey: () => {
    return async (dispatch, getState) => {
      try {
        const {
          auth: { selectedConstruction }
        } = getState();

        dispatch({
          type: constructionVisitConstants.GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_REQUEST
        });

        const { survey, profile: profileSurvey } = await constructionService.getVisitProfileSurvey(
          selectedConstruction
        );

        // 設定為目前處理中的整張問卷
        dispatch(constructionVisitSurveyActions.resetAll());
        dispatch(
          constructionVisitSurveyActions.setSurvey(
            {
              defaultSurvey: survey,
              profile: profileSurvey
            },
            true,
            CONSTRUCTION_VISIT_SURVEY_TYPE.ALL
          )
        );

        dispatch({
          type: constructionVisitConstants.GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_DONE
        });
      } catch (error) {
        dispatch({
          type: constructionVisitConstants.GET_CURRENT_CONSTRUCTION_VISIT_PROFILE_SURVEY_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   * 取得賞屋作業單資料 by 賞屋作業單 UUID
   *
   * @param {string} visitRecordUUID - 賞屋作業單 UUID
   * @param {function} successCb - 成功執行 callback
   */
  getVisitRecordById: (visitRecordUUID, successCb) => {
    return async dispatch => {
      try {
        dispatch({ type: constructionVisitConstants.GET_VISIT_RECORD_BY_ID_REQUEST });
        const record = await constructionVisitService.getVisitRecordById(visitRecordUUID);

        // 設定為目前處理中的整張問卷
        dispatch(
          constructionVisitSurveyActions.setSurvey(
            record,
            false,
            CONSTRUCTION_VISIT_SURVEY_TYPE.ALL
          )
        );

        dispatch({
          type: constructionVisitConstants.GET_VISIT_RECORD_BY_ID_DONE
        });

        successCb && successCb();
      } catch (error) {
        dispatch({
          type: constructionVisitConstants.GET_VISIT_RECORD_BY_ID_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   *  取得賞屋作業單資料 by 手機號碼
   *
   * @param {string} telephone - 手機號碼
   * @param {function} successCb - 成功執行 callback
   */
  getVisitRecordByTelephone: (telephone, successCb) => {
    return async (dispatch, getState) => {
      try {
        const {
          auth: { selectedConstruction }
        } = getState();

        dispatch(maskActions.toggleMask(true));
        const visitRecordInConstructionByTelephone =
          await constructionVisitService.getVisitRecordInConstructionByTelephone(
            selectedConstruction,
            telephone
          );

        successCb && successCb(visitRecordInConstructionByTelephone);
      } catch (error) {
        dispatch(
          modalActions.show("ERROR", {
            content: error.message
          })
        );
      } finally {
        dispatch(maskActions.toggleMask(false));
      }
    };
  },
  /**
   * 取得顧客回饋問卷資料
   *
   * @param {string} constructionVisitUUID - 賞屋作業單 UUID
   */
  getConstructionVisitFeedback: constructionVisitUUID => {
    return async dispatch => {
      try {
        dispatch({
          type: constructionVisitFeedbackConstants.GET_FEEDBACK_REQUEST
        });
        dispatch(maskActions.toggleMask(true));
        const data = await constructionVisitService.getConstructionVisitFeedback(
          constructionVisitUUID
        );

        dispatch({
          type: constructionVisitFeedbackConstants.GET_FEEDBACK_SUCCESS,
          feedbackInfo: data
        });

        // 設定顧客回饋問卷資料
        dispatch(
          constructionVisitSurveyActions.setSurvey(
            {
              ...data.feedback,
              uuid: data.feedback.constructionVisitUUID
            },
            false,
            CONSTRUCTION_VISIT_SURVEY_TYPE.FEEDBACK
          )
        );
      } catch (error) {
        dispatch({
          type: constructionVisitFeedbackConstants.GET_FEEDBACK_FAILURE,
          error: error.message
        });
        dispatch(
          modalActions.show("ERROR", {
            content: error.message
          })
        );
      } finally {
        dispatch(maskActions.toggleMask(false));
      }
    };
  },
  /**
   * 重設賞屋作業單顧客回饋區塊資料
   */
  resetConstructionVisitFeedback: () => {
    return {
      type: constructionVisitFeedbackConstants.RESET_FEEDBACK
    };
  },
  /**
   * 新增賞屋作業單基本資料區塊
   *
   * @param {object} profileInfo - 賞屋作業單基本資料區塊資料
   * @param {function} successCb - 成功執行 callback
   */
  insertConstructionVisitProfile: (profileInfo, successCb) => {
    return async (dispatch, getState) => {
      try {
        const {
          auth: { selectedConstruction }
        } = getState();

        dispatch(maskActions.toggleMask(true));
        const visitUUID = await constructionVisitService.insertConstructionVisitProfile(
          selectedConstruction,
          profileInfo
        );

        successCb && successCb(visitUUID);
      } catch (error) {
        dispatch(
          modalActions.show("ERROR", {
            content: error.message
          })
        );
      } finally {
        dispatch(maskActions.toggleMask(false));
      }
    };
  },
  /**
   * 新增賞屋作業單基本資料區塊
   *
   * @param {string} constructionVisitUUID - 賞屋作業單 UUID
   * @param {object} profileInfo - 賞屋作業單基本資料區塊資料
   * @param {function} successCb - 成功執行 callback
   */
  updateConstructionVisitProfile: (constructionVisitUUID, profileInfo, successCb) => {
    return async dispatch => {
      try {
        dispatch(maskActions.toggleMask(true));
        const visitUUID = await constructionVisitService.updateConstructionVisitProfile(
          constructionVisitUUID,
          profileInfo
        );

        successCb && successCb(visitUUID || constructionVisitUUID);
      } catch (error) {
        dispatch(
          modalActions.show("ERROR", {
            content: error.message
          })
        );
      } finally {
        dispatch(maskActions.toggleMask(false));
      }
    };
  },
  /**
   * 新增賞屋作業單顧客回饋區塊資料
   *
   * @param {string} constructionVisitUUID - 賞屋作業單 UUID
   * @param {object} feedbackInfo - 賞屋作業單顧客回饋區塊資料
   * @param {function} successCb - 成功執行 callback
   */
  insertConstructionVisitFeedback: (constructionVisitUUID, feedbackInfo, successCb) => {
    return async dispatch => {
      try {
        dispatch(maskActions.toggleMask(true));
        await constructionVisitService.insertConstructionVisitFeedback(
          constructionVisitUUID,
          feedbackInfo
        );
        successCb && successCb();
      } catch (error) {
        if (error.isFinished) {
          // 顯示問卷已失效或過期的錯誤頁面
          dispatch({
            type: constructionVisitFeedbackConstants.GET_FEEDBACK_SUCCESS,
            feedbackInfo: {
              isFinished: true
            }
          });
        } else {
          dispatch(
            modalActions.show("ERROR", {
              content: error.message
            })
          );
        }
      } finally {
        dispatch(maskActions.toggleMask(false));
      }
    };
  },
  /**
   * 更新賞屋作業單追蹤處理區塊資料
   *
   * @param {string} constructionVisitUUID - 賞屋作業單 UUID
   * @param {object} trackInfo - 賞屋作業單追蹤處理區塊資料
   */
  updateConstructionVisitTrack: (constructionVisitUUID, trackInfo, successCb) => {
    return async dispatch => {
      try {
        dispatch({ type: constructionVisitConstants.UPDATE_VISIT_TRACK_SURVEY_REQUEST });

        const message = await constructionVisitService.updateConstructionVisitTrack(
          constructionVisitUUID,
          trackInfo
        );

        dispatch(alertActions.show(message));

        successCb && successCb();
      } catch (error) {
        dispatch(alertActions.show(error.message, "error"));
      } finally {
        dispatch({ type: constructionVisitConstants.UPDATE_VISIT_TRACK_SURVEY_DONE });
      }
    };
  },
  /**
   * 取得賞屋作業單留言列表
   *
   * @param {string} constructionVisitUUID - 賞屋作業單 UUID
   */
  getConstructionVisitMessages: constructionVisitUUID => {
    return async dispatch => {
      try {
        dispatch({
          type: constructionVisitConstants.GET_VISIT_MESSAGES_REQUEST
        });
        const messages = await constructionVisitService.getConstructionVisitMessages(
          constructionVisitUUID
        );
        dispatch({
          type: constructionVisitConstants.GET_VISIT_MESSAGES_SUCCESS,
          messages
        });
      } catch (error) {
        dispatch({
          type: constructionVisitConstants.GET_VISIT_MESSAGES_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   * 重設賞屋作業單留言列表資料
   */
  resetConstructionVisitMessages: () => {
    return {
      type: constructionVisitConstants.RESET_VISIT_MESSAGES
    };
  },
  /**
   * 新增追蹤處理留言
   *
   * @param {string} constructionVisitUUID - 賞屋作業單 UUID
   * @param {string} message - 留言訊息
   * @param {string} successCb - 成功執行 callback
   */
  insertConstructionVisitMessage: (constructionVisitUUID, message, successCb) => {
    return async dispatch => {
      try {
        dispatch({
          type: constructionVisitConstants.INSERT_VISIT_MESSAGE_REQUEST
        });
        const { messageItems, message: responseMessage } =
          await constructionVisitService.insertConstructionVisitMessage(
            constructionVisitUUID,
            message
          );

        dispatch({
          type: constructionVisitConstants.GET_VISIT_MESSAGES_SUCCESS,
          messages: messageItems
        });

        dispatch({
          type: constructionVisitRecordsConstants.REPLACE_ITEM_LAST_MESSAGE,
          constructionVisitUUID: constructionVisitUUID,
          messages: messageItems
        });

        dispatch(alertActions.show(responseMessage));

        successCb && successCb(messageItems);
      } catch (error) {
        dispatch(alertActions.show(error.message, "error"));
      } finally {
        dispatch({
          type: constructionVisitConstants.INSERT_VISIT_MESSAGE_DONE
        });
      }
    };
  },
  /**
   * 重設所有狀態
   */
  resetAll: () => {
    return {
      type: constructionVisitConstants.RESET_ALL
    };
  }
};
