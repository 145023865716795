import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import WarningModal from "./WarningModal";
import ConfirmModal from "./ConfirmModal";

const ModalTypes = Object.freeze({
  SUCCESS: SuccessModal,
  ERROR: ErrorModal,
  WARNING: WarningModal,
  CONFIRM: ConfirmModal
});

export default ModalTypes;
