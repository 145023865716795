import { constructionVisitFeedbackConstants } from "../constants";

const initState = {
  feedback: {
    loading: false,
    data: null,
    error: null
  }
};

export const constructionVisitFeedback = (state = initState, action) => {
  switch (action.type) {
    case constructionVisitFeedbackConstants.GET_FEEDBACK_REQUEST: {
      return {
        ...state,
        feedback: {
          ...initState.feedback,
          loading: true
        }
      };
    }
    case constructionVisitFeedbackConstants.GET_FEEDBACK_SUCCESS: {
      return {
        ...state,
        feedback: {
          ...initState.feedback,
          loading: false,
          data: action.feedbackInfo,
          error: null
        }
      };
    }
    case constructionVisitFeedbackConstants.GET_FEEDBACK_FAILURE: {
      return {
        ...state,
        feedback: {
          ...initState,
          loading: false,
          data: null,
          error: action.error
        }
      };
    }
    case constructionVisitFeedbackConstants.RESET_FEEDBACK: {
      return {
        ...state,
        feedback: {
          ...initState
        }
      };
    }
    default:
      return state;
  }
};
