import { constructionConstants } from "../constants";
import { constructionService } from "../services";

export const constructionActions = {
  /**
   * 取得建案清單資料
   */
  getConsructions: () => {
    return async dispatch => {
      try {
        dispatch({
          type: constructionConstants.GET_LIST_REQUEST
        });
        const { items } = await constructionService.getList();
        dispatch({
          type: constructionConstants.GET_LIST_SUCCESS,
          items
        });
      } catch (error) {
        dispatch({
          type: constructionConstants.GET_LIST_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   * 選取建案 by id
   *
   * @param {string} constructionId - 建案 ID
   */
  selectItemById: constructionId => {
    return {
      type: constructionConstants.SELECT_ITEM_BY_ID,
      constructionId
    };
  },
  /**
   * 選擇建案 by uuid
   *
   * @param {string} uuid - 建案 UUID
   */
  selectItemByUUID: uuid => {
    return {
      type: constructionConstants.SELECT_ITEM_BY_UUID,
      constructionUUID: uuid
    };
  },
  /**
   * 清除選取的建案
   */
  clearItem: () => {
    return {
      type: constructionConstants.CLEAR_ITEM
    };
  },
  /**
   * 取得目前所選建案建案銷售人員清單
   *
   * @param {array} constructionVisitUUIDs - 賞屋作業單 UUIDs
   * @param {boolean} isOnJob - 是否在職中
   */
  getSalesPersonList: ({ constructionVisitUUIDs, isOnJob }) => {
    return async (dispatch, getState) => {
      const {
        auth: { selectedConstruction }
      } = getState();

      try {
        dispatch({
          type: constructionConstants.GET_SALES_PERSON_LIST_REQUEST
        });
        const data = await constructionService.getConstructionSalesPersonList(
          selectedConstruction,
          constructionVisitUUIDs,
          isOnJob
        );
        dispatch({
          type: constructionConstants.GET_SALES_PERSON_LIST_SUCCESS,
          items: data
        });
      } catch (error) {
        dispatch({
          type: constructionConstants.GET_SALES_PERSON_LIST_FAILURE,
          error: error.message
        });
      }
    };
  },
  /**
   * 取得目前所選建案建案銷售人員清單 for 查詢條件
   */
  getSalesPersonListForQueryForm: () => {
    return async (dispatch, getState) => {
      const {
        auth: { selectedConstruction }
      } = getState();

      try {
        dispatch({
          type: constructionConstants.GET_SALES_PERSON_LIST_FOR_QUERY_FORM_REQUEST
        });
        const data = await constructionService.getConstructionSalesPersonList(selectedConstruction);
        dispatch({
          type: constructionConstants.GET_SALES_PERSON_LIST_FOR_QUERY_FORM_SUCCESS,
          items: data
        });
      } catch (error) {
        dispatch({
          type: constructionConstants.GET_SALES_PERSON_LIST_FOR_QUERY_FORM_FAILURE,
          error: error.message
        });
      }
    };
  }
};
