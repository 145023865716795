import { authConstants } from "../constants";
import { authService } from "../services";
import { history, router } from "../utils";
import {
  maskActions,
  modalActions,
  constructionReservationActions,
  constructionVisitRecordsActions
} from ".";

export const authActions = {
  /**
   * 後台登入
   *
   * @param {string} loginInfo.telephone - The telephone of user.
   * @param {string} loginInfo.password - The password of user.
   */
  login(loginInfo, errorCb) {
    return async dispatch => {
      try {
        // dispatch(maskActions.toggleMask(true));

        dispatch({
          type: authConstants.LOGIN_REQUEST
        });

        const userInfo = await authService.login(loginInfo);

        // 只有一個負責建案則直接自動選取
        let selectedConstruction = null;
        if (userInfo.constructions_sales_roles.length === 1) {
          selectedConstruction = userInfo.constructions_sales_roles[0].construction_uuid;
        }

        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          userInfo,
          selectedConstruction: selectedConstruction
        });

        // 登入成功 + 已選取建案 則可直接跳轉至原本目標頁面
        if (selectedConstruction) {
          this.__replaceToFromRoute();
        }
      } catch (error) {
        dispatch({
          type: authConstants.LOGING_FAILURE,
          error: error.message
        });

        errorCb && errorCb(error.data);
      }
    };
  },
  /**
   * 後台登出
   */
  logout() {
    return dispatch => {
      dispatch({
        type: authConstants.LOGOUT_SUCCESS
      });

      history.replace({ pathname: router.getPath("Admin.Home") });
    };
  },
  /**
   * 登入選擇建案
   *
   * @param {string} constructionUUID - The construction of uuid.
   */
  selectConstruction(constructionUUID) {
    return dispatch => {
      dispatch({
        type: authConstants.SELECT_CONSTRUCTION,
        selectedConstruction: constructionUUID
      });

      history.push(router.getPath("Admin.Home"));
    };
  },
  /**
   * 切換選擇建案
   *
   * @param {string} constructionUUID - The construction of uuid.
   */
  switchConstruction(constructionUUID) {
    return dispatch => {
      dispatch({
        type: authConstants.SELECT_CONSTRUCTION,
        selectedConstruction: constructionUUID
      });

      history.push(router.getPath("Admin.Home"));
    };
  },
  /**
   * 更新登入資訊
   */
  refresh() {
    return async (dispatch, getState) => {
      const {
        auth: { userInfo, selectedConstruction }
      } = getState();

      if (userInfo) {
        try {
          dispatch(maskActions.toggleMask(true));
          const newestUserInfo = await authService.check(userInfo.uuid);

          // 檢查是否已不再負責先前所選取的建案
          let newSelectedConstruction = null;
          if (
            newestUserInfo.constructions_sales_roles.some(
              c => c.construction_uuid === selectedConstruction
            )
          ) {
            newSelectedConstruction = selectedConstruction;
          }
          dispatch({
            type: authConstants.LOGIN_SUCCESS,
            userInfo: newestUserInfo,
            selectedConstruction: newSelectedConstruction
          });

          // 若已不再負責先前所選擇的建案，則須重新回登入頁選擇建案
          if (!newSelectedConstruction) {
            history.push(router.getPath("Admin.Login"));
          }
        } catch (error) {
          dispatch({
            type: authConstants.LOGING_FAILURE
          });
          dispatch(
            modalActions.show("ERROR", {
              content: error.message,
              onOk: () => {
                dispatch(authActions.logout());
              }
            })
          );
        } finally {
          dispatch(maskActions.toggleMask(false));
        }
      } else {
        dispatch({
          type: authConstants.LOGING_FAILURE
        });
      }
    };
  },
  /**
   * 清除登入錯誤訊息
   */
  clearLoginError() {
    return {
      type: authConstants.CLEAR_LOGIN_FAILURE
    }
  },
  /**
   * 導頁至上一頁或首頁
   */
  __replaceToFromRoute() {
    const { from } = history.location.state || {
      from: { pathname: router.getPath("Admin.Home") }
    };
    history.replace(from);
  },
};
