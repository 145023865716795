import { alertConstants } from "../constants";

const initState = {
  open: false,
  message: "",
  alertType: "success"
};

export const alert = (state = initState, action) => {
  switch (action.type) {
    case alertConstants.SHOW:
      return {
        ...state,
        open: true,
        message: action.message,
        alertType: action.alertType
      };
    case alertConstants.HIDE:
      return {
        ...state,
        open: false,
        message: ""
      };
    default:
      return state;
  }
};
