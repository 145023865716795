import React from "react";
import Layout from "./layouts";
import AdminLayout from "./layouts/Admin";
import { Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { history } from "./utils";
import useRouteTitle from "./hooks/useRouteTitle";

const useStyles = makeStyles(theme => ({
  backdrop: {
    // 覆蓋所有元件
    zIndex: theme.zIndex.tooltip + 1
  }
}));

// loading 畫面
const Loading = () => {
  const classes = useStyles();
  const { spinning } = useSelector(state => state.mask);
  return (
    <Backdrop open={spinning} className={classes.backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const Routes = () => {
  // 監聽 url 變化，並自動串上 title
  useRouteTitle();

  return (
    <Switch>
      <Route path="/Admin" component={AdminLayout} />
      <Route path="/" component={Layout} />
    </Switch>
  );
};

function App() {
  return (
    <>
      {/* 全版 loading 畫面 */}
      <Loading />
      {/* layout 畫面 */}
      <Router history={history}>
        <Routes />
      </Router>
    </>
  );
}

export default App;
