export const constructionVisitRecordsConstants = {
  // 取得賞屋作業單列表相關
  GET_ALL_REQUEST: "CONSTRUCTION_VISIT_RECORDS_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "CONSTRUCTION_VISIT_RECORDS_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "CONSTRUCTION_VISIT_RECORDS_GET_ALL_FAILURE",
  REPLACE_ITEM : "CONSTRUCTION_VISIT_RECORDS_REPLACE_ITEM",
  CLEAR_ITEM_HIGHLIGHT: "CONSTRUCTION_VISIT_RECORDS_CLEAR_ITEM_HIGHLIGHT",
  REPLACE_ITEM_LAST_MESSAGE: "CONSTRUCTION_VISIT_RECORDS_REPLACE_ITEM_LAST_MESSAGE",
  REPLACE_KEEP_TRACKING_INFO: "CONSTRUCTION_VISIT_RECORDS_REPLACE_KEEP_TRACKING_INFO",
  REPLACE_VISIT_AGAIN_INFO: "CONSTRUCTION_VISIT_RECORDS_REPLACE_VISIT_AGAIN_INFO",
  RESET_ITEMS: "CONSTRUCTION_VISIT_RECORDS_RESET_ITEMS",
  RESET_GET_ALL: "CONSTRUCTION_VISIT_RECORDS_RESET_GET_ALL",

  // 指派人員 & 移除指派人員相關
  SETTING_VISIT_RECPTIONIST_REQUEST: "CONSTRUCTION_VISIT_SETTING_VISIT_RECPTIONIST_REQUEST",
  SETTING_VISIT_RECPTIONIST_SUCCESS: "CONSTRUCTION_VISIT_SETTING_VISIT_RECPTIONIST_SUCCESS",
  SETTING_VISIT_RECPTIONIST_FAILURE: "CONSTRUCTION_VISIT_SETTING_VISIT_RECPTIONIST_FAILURE",

  // 設定忽略 & 移除忽略
  IGNORE_VISIT_RECORD_REQUEST: "CONSTRUCTION_VISIT_IGNORE_VISIT_RECORD_REQUEST",
  IGNORE_VISIT_RECORD_SUCCESS: "CONSTRUCTION_VISIT_IGNORE_VISIT_RECORD_SUCCESS",
  IGNORE_VISIT_RECORD_FAILURE: "CONSTRUCTION_VISIT_IGNORE_VISIT_RECORD_FAILURE",

  // 設定約定接待時間
  SETTING_APPOINTMENT_AT_REQUEST: "CONSTRUCTION_VISIT_RECORDS_SETTING_APPOINTMENT_AT_REQUEST",
  SETTING_APPOINTMENT_AT_SUCCESS: "CONSTRUCTION_VISIT_RECORDS_SETTING_APPOINTMENT_AT_SUCCESS",
  SETTING_APPOINTMENT_AT_FAILURE: "CONSTRUCTION_VISIT_RECORDS_SETTING_APPOINTMENT_AT_FAILURE",

  // 取消約定接待時間
  CANCEL_APPOINTMENT_AT_REQUEST: "CONSTRUCTION_VISIT_RECORDS_CANCEL_APPOINTMENT_AT_REQUEST",
  CANCEL_APPOINTMENT_AT_SUCCESS: "CONSTRUCTION_VISIT_RECORDS_CANCEL_APPOINTMENT_AT_SUCCESS",
  CANCEL_APPOINTMENT_AT_FAILURE: "CONSTRUCTION_VISIT_RECORDS_CANCEL_APPOINTMENT_AT_FAILURE",
  RESET_APPOINTMENT: "CONSTRUCTION_VISIT_RECORDS_RESET_APPOINTMENT"
};
