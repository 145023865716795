import { router } from "./";

const SPLITE_STRING = " | ";

export const docTitle = {
  /**
   * 串接頁面 title
   * @param {string}} appendTitle 標題
   */
  append(appendTitle) {
    let title = document.title;
    const lastIndexOf = title.lastIndexOf(SPLITE_STRING);
    if (lastIndexOf > -1) {
      title = title.substring(lastIndexOf + SPLITE_STRING.length, title.length);
    }

    document.title = appendTitle + SPLITE_STRING + title;
  },
  /**
   * 依據路由資訊串接對應的頁面名稱
   * @param {object} location 路由資訊
   */
  refreshTitleByRoute(location) {
    const key = router.getKey(location.pathname);
    const name = router.getName(key);
    let title = document.title;
    const lastIndexOf = title.lastIndexOf(SPLITE_STRING);
    if (lastIndexOf > -1) {
      title = title.substring(lastIndexOf + SPLITE_STRING.length, title.length);
    }
    document.title = (name ? (name + SPLITE_STRING) : "") + title;
  }
};
