import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import { useLocation, matchPath } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { constructionActions } from "../actions";
import HeaderDesktopMenus from "./HeaderDesktopMenus";
import HeaderMobileMenus from "./HeaderMobileMenus";
import { router } from "../utils";

const useStyles = makeStyles(theme => ({
  menusRoot: {
    display: "flex",
    marginLeft: "auto",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(3)
    }
  }
}));

const constructionStatusColorMap = new Map([
  ["sellout", { bgColor: "rgb(255 251 251)" }],
  ["selling", { bgColor: "rgb(255 241 242)" }],
  ["coming_soon", { bgColor: "rgb(255 239 233)" }]
]);

const relatedCompanies = [
  {
    title: "華友聯集團",
    url: "https://www.huayulien.com/"
  },
  {
    title: "華友聯開發",
    url: "https://www.huakai.com.tw/"
  },
  {
    title: "墾丁 H 會館",
    url: "https://h-resort.com/"
  },
  {
    title: "台南清水漾",
    url: "https://www.h-villainn.com/"
  }
];

const downloadAppUrl = process.env.REACT_APP_DOWNLOAD_URL;

const HeaderMenus = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { items = [] } = useSelector(state => state.construction.list);
  const selectedConstruction = useSelector(state => state.construction.selectedItem);
  const location = useLocation();

  // 依據 url 選取對應建案
  useEffect(() => {
    const match = matchPath(location.pathname, router.getPath("ReserveConstruction"));
    if (match) {
      dispatch(constructionActions.selectItemByUUID(match.params.constructionUUID));
    }
  }, [location, items, dispatch]);

  const handleSelectConstruction = constructionId => {
    dispatch(constructionActions.selectItemById(constructionId));
  };

  return (
    <div className={classes.menusRoot}>
      <Hidden smDown>
        <HeaderDesktopMenus
          selectedConstruction={selectedConstruction}
          constructions={items}
          constructionStatusColorMap={constructionStatusColorMap}
          onSelectConstruction={handleSelectConstruction}
          relatedCompanies={relatedCompanies}
          downloadAppUrl={downloadAppUrl}
        />
      </Hidden>
      <Hidden mdUp>
        <HeaderMobileMenus
          selectedConstruction={selectedConstruction}
          constructions={items}
          constructionStatusColorMap={constructionStatusColorMap}
          onSelectConstruction={handleSelectConstruction}
          relatedCompanies={relatedCompanies}
          downloadAppUrl={downloadAppUrl}
        />
      </Hidden>
    </div>
  );
};

export default HeaderMenus;
